import { ref } from '@vue/composition-api';

const manuallyControlled = ref(false);
const menuOpen = ref(false);
const projectsOpen = ref(false);

export {
  menuOpen,
  projectsOpen,
  manuallyControlled,
};
