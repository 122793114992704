import { ucfirst } from '@/providers/helpers';
import AuthRoutes from '@/modules/auth/routes';
import CoreRoutes from '@/modules/core/routes';
import CrmRoutes from '@/modules/crm/routes';
import FormRoutes from '@/modules/form/routes';
import PimRoutes from '@/modules/pim/routes';
import MapRoutes from '@/modules/map/routes';
import OfferRoutes from '@/modules/offer/routes';
import WebsiteRoutes from '@/modules/website/routes';
import ProjectLayout from '@/modules/core/views/layouts/project';
import SettingsRoutes from '@/modules/settings/routes';
import Vue from 'vue';
import VueRouter from 'vue-router';
import useAuth from '@/compositions/useAuth';

const { validateAuthentication, me } = useAuth();

Vue.use(VueRouter);

const moduleRoutes = [
  ...CoreRoutes,
  ...AuthRoutes,
  ...PimRoutes,
  ...CrmRoutes,
  ...FormRoutes,
  ...MapRoutes,
  ...OfferRoutes,
  ...WebsiteRoutes,
  ...SettingsRoutes,
];

const routes = [
  ...moduleRoutes.filter(route => !route.projectScope),
  {
    path: '/projects/:project_id/',
    component: ProjectLayout,
    children: [...moduleRoutes.filter(route => route.projectScope)],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export function redirectToUserRelevantPage(authUser, next) {
  if (authUser.recent_project && authUser.recent_project.id) {
    next({
      name: 'dashboard',
      params: { project_id: authUser.recent_project.id },
      replace: true,
    });
  } else if (authUser.customer && authUser.customer.projects.length > 0) {
    next({
      name: 'dashboard',
      params: { project_id: authUser.customer.projects[0].id },
      replace: true,
    });
  } else if (authUser.customer && authUser.customer.projects.length === 0) {
    next({
      name: 'onboarding',
      replace: true,
    });
  } else {
    next({
      name: 'logout',
      replace: true,
    });
  }
}

const guestRoutes = [
  'login',
  'password-recover',
  'password-reset',
  'verify-user',
];

router.beforeEach(async (to, from, next) => {
  if (to.name) {
    document.title = `${ucfirst(to.meta.title)} | Prasset`;
  }

  let authUser = me.value !== null ? me.value : null;

  if (!authUser) {
    try {
      authUser = await validateAuthentication();
    } catch {
      // fall-through.
    }
  }

  if (to.name === 'login' && authUser) {
    redirectToUserRelevantPage(authUser, next);
  } else if (!guestRoutes.includes(to.name)) {
    if (authUser) {
      next();
    } else {
      next({
        name: 'login',
        replace: true,
      });
    }
  } else {
    next();
  }
});

export default router;
