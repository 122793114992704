export default [
  {
    projectScope: true,
    name: 'settings',
    path: 'settings',
    redirect: to => ({
      name: 'settings-index',
      params: {
        project_id: to.params.project_id,
        type: 'projects',
      },
    }),
  },
  {
    projectScope: true,
    name: 'settings-index',
    path: 'settings/:type',
    component: () =>
      import(
        /* webpackChunkName: "Index" */ '@/modules/settings/views/templates/Index'
      ),

    children: [
      {
        projectScope: true,
        name: 'settings-create',
        path: 'create',
        component: () =>
          import(
            /* webpackChunkName: "Create" */ '@/modules/settings/views/templates/Create'
          ),
        sidebar: true,
      },
      {
        projectScope: true,
        name: 'settings-view',
        path: ':entry',
        component: () =>
          import(
            /* webpackChunkName: "View" */ '@/modules/settings/views/templates/View'
          ),
        sidebar: true,
      },
      {
        projectScope: true,
        name: 'settings-edit',
        path: ':entry/edit',
        component: () =>
          import(
            /* webpackChunkName: "Edit" */ '@/modules/settings/views/templates/Edit'
          ),
        sidebar: true,
      },
    ],
  },
];
