<template>
  <div class="daterangefield relative">
    <FlatPickr
        class="bg-white placeholder-gray-600 font-base font-normal rounded border border-gray-300 py-2 px-4 w-full focus:outline-none focus:border-prasset-green-500"
        :class="{'text-input--error': (error && error.length > 0)}"
        :placeholder="placeholder"
        :required="required"
        :value="value"
        :config="pickrConfig"
        :id="id"
        @input="$emit('input', $event)"
    />
    <InputValidator :errors="error" class="text-input__error" />
  </div>
</template>


<script>
import 'flatpickr/dist/flatpickr.css';
import { Dutch } from 'flatpickr/dist/l10n/nl.js';
import FlatPickr from 'vue-flatpickr-component';
import InputValidator from '@/components/alerts/InputValidator';

export default {
  components: {
    InputValidator,
    FlatPickr,
  },

  props: {
    value: {
      type: String,
      default: null,
    },

    config: {
      type: Object,
      default: () => {},
    },

    placeholder: {
      type: String,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: 'input',
    },

    id: {
      type: String,
      default: null,
    },

    error: {
      value: [Array, Object],
      default: () => {
        return null;
      },
    },
  },

  data() {
    return {
      pickrConfig: {
        altFormat: 'd-m-Y',
        altInput: true,
        locale: Dutch,
        mode: 'range',
        ...this.config,
      },
    };
  },
};
</script>
