<template>
  <div class="backdrop" @click="handleClose">
    <div class="dialog" role="dialog" @click.stop>
      <button v-if="isDismissable" class="dialog__close" @click="handleClose">&times;</button>
      <main class="dialog__body text-gray-700 p-8">
        <h1 v-if="title" class="text-xl font-medium text-prasset-gray-900 mb-2" v-html="title"></h1>
        <div class="col-span-1">
          <slot />
        </div>
      </main>
      <footer v-if="hasFooter" class="dialog__footer">
        <slot name="footer" />
        <button v-if="cancelButton" class="button button--outlined ml-2" type="button" @click="handleClose">{{ cancelButton }}</button>
        <button v-if="confirmButton" class="button button--opague ml-2" type="button" @click="handleConfirm">{{ confirmButton }}</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },

    isDismissable: {
      type: Boolean,
      default: true,
    },

    confirmButton: {
      type: String,
      default: null,
    },

    cancelButton: {
      type: String,
      default: null,
    },

    onConfirm: {
      type: Function, // Function or Promise
      default: undefined,
    },
  },

  created() {
    if (this.isDismissable) {
      document.addEventListener('keyup', this.handleKeyup);
    }
  },

  beforeDestroy () {
    if (this.isDismissable) {
      document.removeEventListener('keyup', this.handleKeyup);
    }
  },

  computed: {
    hasFooter() {
      return this.$slots.footer || this.confirmButton || this.cancelButton;
    },
  },

  methods: {
    handleConfirm() {
      if (this.onConfirm) {
        if (this.onConfirm instanceof Promise) {
          this.onConfirm().then(function() {
            if (this.isDismissable) {
              this.$emit('close');
            }
          });
        } else {
          this.onConfirm();
          if (this.isDismissable) {
            this.$emit('close');
          }
        }
      } else {
        if (this.isDismissable) {
          this.$emit('close');
        }
      }
    },

    handleClose() {
      if (this.isDismissable) {
        this.$emit('close');
      }
    },

    handleKeyup (e) {
      if (e.keyCode === 27) {
        this.handleClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @keyframes revealUp {
    0% {
      animation-timing-function: cubic-bezier(.13,.84,.82,1);
      opacity: .4;
      transform: translateY(.8rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .backdrop {
    @apply .fixed
           .inset-0
           .overflow-auto
           .flex
           .justify-center
           .items-center
           .p-8
           .bg-prasset-gray-900
           .bg-opacity-25;

    z-index: 9999;
  }

  .dialog {
    @apply .relative
           .rounded
           .bg-white
           .shadow-lg
           .w-full
           .select-none;

    max-width: 400px;
    animation: revealUp 250ms;

    &__close {
      @apply .bg-transparent
             .border-0
             .outline-none
             .text-2xl
             .absolute
             .leading-none
             .right-0
             .top-0
             .text-gray-300
             .w-8
             .h-8
             .cursor-pointer;

      &:hover, &:focus {
        @apply .text-prasset-gray-800;
      }
    }

    &__footer {
      @apply .border-t
             .border-gray-300
             .py-4
             .px-8
             .flex
             .justify-end;
    }
  }
</style>
