<template>
  <div
    class="filesfield relative border border-gray-300 bg-white rounded p-4"
    @drop.prevent="addFile($event.dataTransfer.files)"
    @dragover.prevent
  >
    <label class="relative block text-prasset-gray-800" :class="{ 'cursor-pointer': !maxFilesExeeded }">
      <span v-if="!maxFilesExeeded">
        <input
          type="file"
          multiple
          :accept="accepted"
          class="w-px h-px opacity-0 overflow-hidden absolute"
          @change="addFile($event.target.files)"
        />
        Selecteer of plaats hier bestanden om toe te voegen. <br />
        <span class="font-medium">Klik hier om bestanden te selecteren</span>
      </span>
      <span v-if="maxFiles" class="text-sm block text-prasset-gray-400">
        Maximaal {{ maxFiles }}
        <span v-if="maxFiles === 1">bestand</span>
        <span v-if="maxFiles > 1">bestanden</span>
        <span v-if="maxFilesExeeded">. Verwijder eerst een bestand om een nieuw bestand te kunnen toevoegen.</span>
      </span>
    </label>
    <ul v-if="files.length > 0 || uploads.length > 0" class="mt-4 -mx-4 -mb-4 divide-y divide-grey-200 border-t border-grey-200">
      <li v-for="file in uploads" :key="`${file.id}`" class="flex justify-between items-center w-full px-4 hover:bg-gray-100">
        <FileField
          :file="file"
          :isUploaded="false"
          :isConfidential="isConfidential"
          :meta="meta"
          @removed="removedFile"
          @uploaded="uploadedFile"
        />
      </li>
      <li v-for="file in files" :key="`${file.id}`" class="flex justify-between items-center w-full px-4 hover:bg-gray-100">
        <FileField
          :file="file"
          :isUploaded="true"
          :isConfidential="isConfidential"
          @removed="removedFile"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import findIndex from 'lodash/findIndex';
import { toRefs, reactive, computed, watch } from '@vue/composition-api';
import { generateUUID } from '@/providers/helpers';
import FileField from '@/components/field/FileField';

export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },

  components: {
    FileField,
  },

  props: {
    name: {
      type: String,
      default: 'input',
    },

    entryType: {
      type: String,
    },

    entryId: {
      type: String,
    },

    collection: {
      type: String,
      default: 'images',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    config: {
      type: Object,
      default: () => ({}),
    },

    subSelection: {
      type: Array,
      default: null,
    },

    existingFiles: {
      type: Array,
      default: () => [], // bestaande array met File objecten
    },

    modelValue: {
      type: Array,
      default: () => [], // array met media ID's
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      files: [],
      uploads: [],
      previews: [],
      categories: {
        images: ['jpg', 'jpeg', 'webp', 'png'],
        animations: ['gif', 'apng'],
        videos: ['avi', 'mpeg'],
        audio: ['mp3'],
        documents: ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'],
        archives: ['zip', 'rar'],
      },
    });

    state.files = props.existingFiles.filter(file => {
      if (props.subSelection !== null) {
        return props.subSelection.includes(file.id);
      }
      return true;
    }).map(file => {
      return {
        ...file,
        name: file.id,
        size: file.original_size,
        type: file.mime_type,
      };
    });

    watch(() => state.files, value => {
      emit('change', value.map(file => file.id));
    });

    const maxFiles = computed(() => {
      return props.config.maxFiles;
    });

    const isConfidential = computed(() => {
      return props.config.confidential ? props.config.confidential : false;
    });

    /**
     * Accepted and acceptedCategories can be combined.
     * By default only images are accepted.
     *
     * "config": {
     *   "accepted": "*",                           // All filetypes accepted
     *   "accepted": "all",                         // All filetypes accepted
     *   "accepted": ".bmp,.gif'",                  // Accept .bmp and .gif extensions.
     *   "accepted": ["bmp", "gif"],                // Accept .bmp and .gif extensions.
     *   "acceptedCategories": ["images", "audio"], // Accept images and audio extensions.
     *   "acceptedCategories": "all",               // Accept all categories.
     * }
     */
    const accepted = computed(() => {
      let extensions = [];

      if (props.config && props.config.accepted && ['*', 'all'].includes(props.config.accepted)) {
        return '*/*';
      }

      // add custom accepted file extensions.
      // accepts array of extensions or comma-seperated string.
      if (props.config && props.config.accepted) {
        let exts = props.config.accepted;
        if (!Array.isArray(props.config.accepted)) {
          exts = props.config.accepted.split(',').map(a => a.replace('.', ''));
        }
        extensions = extensions.concat(exts);
      }

      // add extensions from categories.
      if (props.config && props.config.acceptedCategories && ['*', 'all'].includes(props.config.acceptedCategories)) {
        Object.values(state.categories).forEach(exts => {
          extensions = extensions.concat(exts);
        });
      } else if (props.config && props.config.acceptedCategories && Array.isArray(props.config.acceptedCategories)) {
        props.config.acceptedCategories.forEach(categoryName => {
          extensions = extensions.concat(state.categories[categoryName] || []);
        });
      } else if (props.config && !props.config.accepted && !props.config.acceptedCategories) {
        ['images'].forEach(categoryName => {
          extensions = extensions.concat(state.categories[categoryName] || []);
        });
      }

      return extensions.map(extension => `.${extension.trim()}`).join(',');
    });

    const maxFilesExeeded = computed(() => {
      return !(!props.config.maxFiles || props.config.maxFiles && state.files.length + state.uploads.length + 1 <= props.config.maxFiles);
    });

    const meta = computed(() => {
      return {
        entryType: props.entryType,
        entryId: props.entryId,
        collection: props.collection,
      };
    });

    function addFile(droppedFiles) {
      if (!droppedFiles) return;

      ([...droppedFiles]).forEach(file => {
        if (!props.config.maxFiles || props.config.maxFiles && state.files.length + state.uploads.length + 1 <= props.config.maxFiles) {
          file.id = generateUUID();
          state.uploads.push(file);
        } else {
          console.log('maxFilesExeeded', props.config.maxFiles);
        }
      });
    }

    function removedFile(isUploaded, file) {
      if (isUploaded) {
        const index = findIndex(state.files, { id: file.id });
        state.files.splice(index, 1);
      } else {
        const index = findIndex(state.uploads, { id: file.id });
        state.uploads.splice(index, 1);
      }
    }

    function uploadedFile(responseFile, orignalFile) {
      state.uploads = state.uploads.filter(uploadedFile => {
        return uploadedFile.id !== orignalFile.id;
      });

      state.files.unshift(responseFile);
    }

    return {
      ...toRefs(state),
      maxFiles,
      accepted,
      addFile,
      removedFile,
      uploadedFile,
      maxFilesExeeded,
      meta,
      isConfidential,
    };
  },
};
</script>
