import axios from 'axios';
import serveFile from 'js-file-download';
import EventBus from '@/eventbus';
import Vue from 'vue';
import VueCompositionAPI, { reactive } from '@vue/composition-api';
import useLocale from '@/compositions/useLocale';

Vue.use(VueCompositionAPI);

const { getActiveLocale } = useLocale();

export const state = reactive({
  lastResponse: null,
});

export default () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      // 'Accept-Language': getActiveLocale().language,
    },
  });

  instance.interceptors.response.use(
    response => {
      state.lastResponse = new Date().getTime();
      EventBus.$emit('close', { name: 'session-expired' });
      return response;
    },
    error => {
      if (error instanceof axios.Cancel === false && error.config.url.indexOf('auth/') === -1 && error.response && error.response.status === 401) {
        EventBus.$emit('confirm:open', {
          name: 'session-expired',
          title: 'Let op',
          main: 'Uw inlog sessie is verlopen.',
          isDismissable: false,
          confirmButton: 'Opnieuw inloggen',
          onConfirm: () => {
            document.location.href = '/';
          },
        });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export function createCancelToken() {
  return axios.CancelToken.source();
}

export function cancelRequest(cancelToken) {
  if (cancelToken && cancelToken.cancel) {
    cancelToken.cancel();
  }
}

export function isCanceled(response) {
  return axios.isCancel(response);
}

export function setAccessToken(token) {
  axios.defaults.headers['Authorization'] = `Bearer ${token}`;
  window.localStorage.accessToken = token;
}

export async function downloadAs(url, params = {}, filename = 'report.csv') {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      // 'Accept-Language': getActiveLocale().language,
    },
    responseType: 'blob',
  });

  const response = await instance.get(url, { params });
  serveFile(response.data, filename);
}
