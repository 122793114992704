<template>
  <Portal to="modals" v-if="isOpen">
    <Overlay :fixed="true" class="z-50 bg-prasset-gray-900 flex justify-center items-center p-8 overflow-auto" @click.native="hide">
      <div role="dialog" class="modal relative w-full max-w-md bg-white shadow rounded" @click.stop>
        <button type="button" @click="hide" class="bg-transparent border-0 outline-none text-2xl absolute leading-none right-0 top-0 text-gray-300 w-8 h-8 cursor-pointer hover:text-prasset-gray-800 focus:text-prasset-gray-800">&times;</button>
        <main class="text-gray-700 p-8">
          <slot />
        </main>
        <div v-if="$slots.footer" class="border-t border-gray-300 py-4 px-8 flex justify-end">
          <slot name="footer" />
        </div>
      </div>
    </Overlay>
  </Portal>
</template>

<script>
import { Portal } from 'portal-vue';
import { reactive, toRefs } from '@vue/composition-api';
import Overlay from '@/components/Overlay';

export default {
  name: 'Modal',

  components: {
    Overlay,
    Portal,
  },

  setup() {
    const state = reactive({
      isOpen: false,
    });

    function hide() {
      state.isOpen = false;
    }

    function show() {
      state.isOpen = true;
    }

    return {
      ...toRefs(state),
      hide,
      show,
    };
  },
};
</script>

<style lang="scss">
  .modal {
    animation: revealUp 250ms;
  }
  @keyframes revealUp {
    0% {
      animation-timing-function: cubic-bezier(.13,.84,.82,1);
      opacity: .4;
      transform: translateY(.8rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
</style>
