<template>
  <div class="pillfield relative select-none bg-white block border border-gray-300 rounded relative text-prasset-gray-800" :class="optionClasses">
    <label>
      <div v-if="hasSelectedOption" class="flex flex-wrap pt-2 pl-2">
        <div
          v-for="item in selectedOption" :key="item.key"
          @click="toggleItem(item.key)"
          :tabindex="!disabled && !readonly"
          class="pillfield__pill relative max-w-full flex py-1 px-2 items-center text-sm text-white leading-none cursor-pointer rounded-sm mr-2 mb-2"
          :class="{
            'bg-prasset-gray-300': loading,
            'bg-prasset-gray-800': !item.error,
            'bg-prasset-red-600': item.error
          }"
        >
          <LoadingIndicator v-if="loading" />
          <span v-if="!loading" class="max-w-42 truncate">{{ item.name }}</span>
          <span v-if="!disabled && !readonly" class="font-medium text-lg block flex-shrink-0 ml-2">&times;</span>
        </div>
      </div>
      <div v-if="!readonly" class="pillfield__input relative rounded overflow-hidden border-t border-gray-200">
        <input type="text" v-model="searchValue" @keydown="onSearchKeyDown" @keyup="onSearchKey" :id="id" :placeholder="placeholder" :disabled="disabled" class="bg-white placeholder-gray-600 font-base font-normal py-2 px-4 w-full focus:outline-none" />
      </div>
    </label>
    <div class="pillfield__flyout hidden absolute overflow-auto max-h-64 p-full left-0 mt-1 w-full bg-white shadow-lg rounded-sm">
      <LoadingIndicator v-if="suggestionsLoading" class="text-prasset-gray-400 bg-white w-4 absolute mb-4 mr-4 bottom-0 right-0 z-1" />
      <a v-for="(name, value) in availableOptions" :key="value" href="javascript:void()" @click.prevent="toggleItem(value)" class="block w-full text-left py-2 px-4 focus:outline-none focus:bg-gray-100 hover:bg-gray-100">{{ name }}</a>
      <div v-if="endpoint" class="border-t border-gray-200 text-prasset-gray-400 px-4 py-2 text-sm leading-snug">
        <i class="ri-keyboard-box-fill align-bottom"></i>
        {{ $tuf('item_not_found_question_type_to_search') }}
      </div>
    </div>
    <InputValidator :errors="error" class="text-input__error" />
  </div>
</template>

<script>
import { reactive, toRefs, computed, onMounted, set } from '@vue/composition-api';
import { asyncForEach } from '@/providers/helpers';
import Api, { isCanceled, cancelRequest, createCancelToken } from '@/providers/api';
import InputValidator from '@/components/alerts/InputValidator';
import LoadingIndicator from '@/components/LoadingIndicator';

export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },

  components: {
    InputValidator,
    LoadingIndicator,
  },

  props: {
    id: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Object,
      default: () => ({}),
    },

    endpoint: {
      type: String,
      default: undefined,
    },

    error: {
      value: [Array, Object],
      default: () => {
        return null;
      },
    },

    modelValue: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {

    const state = reactive({
      searchValue: '',
      optionValues: {},
      loading: false,
      suggestionsLoading: false,
      cancelToken: null,
    });

    function toggleItem(key) {
      if (props.disabled || props.readonly) {
        return;
      }

      let value = key;
      if (props.modelValue === key) {
        value = null;
      }

      emit('change', value);
    }

    function onSearchKeyDown(event) {
      if (event.keyCode === 13) { // enter key
        event.preventDefault();
        if (state.searchValue.length > 0 && hasAvailableOptions.value) {
          toggleItem(Object.keys(availableOptions.value)[0]);
          state.searchValue = '';
        }
      }
    }

    function onSearchKey() {
      loadSuggestions();
    }

    async function loadInitialOptionValues() {
      if (props.endpoint) {
        state.loading = true;

        try {
          await asyncForEach(props.modelValue, async (uuid) => {
            try {
              const response = await Api().get(`${props.endpoint}/${uuid}`);
              const item = response.data.data;
              set(state.optionValues, item.id, item.name);
            } catch (error) {
              console.error(error);
            }
          });
        } catch {
          // silence is key
        }

        state.loading = false;
      }

      // simple values.
      if (!props.endpoint && props.options && props.options !== null) {
        state.optionValues = props.options;
      }
    }

    async function loadSuggestions() {
      if (!props.endpoint) return;

      cancelRequest(state.cancelToken);
      state.cancelToken = createCancelToken();
      state.suggestionsLoading = true;

      try {
        const response = await Api().get(props.endpoint, {
          cancelToken: state.cancelToken.token,
          params: { 'page': 1, 'per_page': 30, 'filter[autosuggest]': state.searchValue },
        });

        response.data.data.map(item => {
          set(state.optionValues, item.id, item.name);
        });
      } catch (error) {
        if (!isCanceled(error)) {
          console.error(error);
        }
      }

      state.suggestionsLoading = false;
    }

    const optionClasses = computed(() => {
      return {
        'pillfield--disabled': props.disabled,
        'text-input--error': props.error && props.error.length > 0,
      };
    });

    const availableOptions = computed(() => {
      const available = {};

      const limit = props.endpoint ? 30 : 999;
      let count = 0;

      Object.keys(state.optionValues).map(key => {
        if (count > limit) return;

        if (!props.modelValue || props.modelValue !== key && state.optionValues[key]) {
          if (state.searchValue.length > 0) {
            if (state.optionValues[key].toLowerCase().indexOf(state.searchValue.toLowerCase()) !== -1) {
              available[key] = state.optionValues[key];
              count++;
            }
          } else {
            available[key] = state.optionValues[key];
            count++;
          }
        }
      });

      return available;
    });

    const selectedOption = computed(() => {
      const selected = [];

      const optionValuesKeys = Object.keys(state.optionValues);

      if (props.modelValue) {
        const key = props.modelValue;
        if (optionValuesKeys.indexOf(key) !== -1) {
          selected.push({ key, name: state.optionValues[key], error: false });
        } else {
          selected.push({ key, name: key, error: true });
        }
      }

      return selected;
    });

    const hasSelectedOption = computed(() => {
      return selectedOption.value.length > 0;
    });

    const hasAvailableOptions = computed(() => {
      return Object.keys(availableOptions.value).length > 0;
    });

    onMounted(() => {
      loadInitialOptionValues();
      loadSuggestions(); // load first page of suggestions.
    });

    return {
      ...toRefs(state),
      availableOptions,
      loadSuggestions,
      loadInitialOptionValues,
      hasAvailableOptions,
      hasSelectedOption,
      onSearchKey,
      onSearchKeyDown,
      optionClasses,
      selectedOption,
      toggleItem,
    };
  },
};
</script>

<style lang="scss">
.pillfield {
    min-height: 2.65rem;

    &__input:after {
        content: '';

        @apply .absolute
               .right-0
               .bottom-0
               .top-0
               .text-xs
               .bg-white
               .px-4
               .flex
               .items-center
               .pointer-events-none
               .bg-no-repeat
               .bg-center;

        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="5"%3E%3Cg transform="translate(-302 -48)"%3E%3Cpath d="M306 52.667l-4-4h8z" fill="%231A282F" /%3E%3C/g%3E%3C/svg%3E%0A');
    }

    &:focus-within {
      @apply .border-prasset-green-500;
    }

    &:focus-within &__flyout {
      @apply .block;
    }

    &--readonly {
        @apply .bg-gray-100;

        &:after {
            @apply .bg-gray-100;
        }
    }

    &--disabled {
        @apply .bg-gray-100
               .text-gray-300;

        &:after {
            @apply .bg-gray-100;
        }
    }
}
</style>
