import { ref, computed } from '@vue/composition-api';
import Api from '@/providers/api';

const project = ref(null);
const projects = ref([]);

function getProject(id) {
  return new Promise((resolve, reject) => {
    Api().get(`projects/${id}`).then(({ data }) => {
      project.value = data.data;
      resolve(data.data);
    })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

async function fetchProjects() {
  const response = await Api().get('projects');
  projects.value = response.data.data;
}

const otherProjects = computed(() => {
  return projects.value.filter(p => p.id !== project.value.id);
});

export {
  project,
  projects,
  getProject,
  fetchProjects,
  otherProjects,
};
