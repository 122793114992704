<template>
  <div class="bg-prasset-gray-900 absolute inset-0 flex justify-center items-center p-4">
    <div class="w-full max-w-102">
      <RouterLink :to="{ name: 'login' }">
        <img class="logo mx-auto mb-6" src="@/assets/logo/prasset-white.svg" />
      </RouterLink>
      <div class="bg-gray-100 rounded-lg border border-grey-200 py-12 px-6">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
};
</script>
