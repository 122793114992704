import axios from 'axios';
import Vue from 'vue';
import VueCompositionAPI, { toRefs, reactive, getCurrentInstance } from '@vue/composition-api';
import find from 'lodash/find';

Vue.use(VueCompositionAPI);

const state = reactive({
  locale: 'nl_NL',
  locales: [
    { locale: 'nl_NL', language: 'nl' },
    { locale: 'en_US', language: 'en' },
  ],
});

export default function useLocale() {

  const context = getCurrentInstance();

  function getLocale(locale) {
    return find(state.locales, { locale });
  }

  function isLocale(locale) {
    return getLocale(locale) ? true : false;
  }

  function getActiveLocale() {
    return getLocale(state.locale);
  }

  function isActiveLocale(locale) {
    return getActiveLocale().locale === locale.locale;
  }

  function setLocale(locale) {
    if (isLocale(locale)) {
      state.locale = locale;
      context.$i18n.locale = locale;
      window.localStorage.locale = locale;
      axios.defaults.headers.common['Accept-Language'] = locale.language;
    }
  }

  function loadLocale() {
    const locale = window.localStorage.locale;
    if (isLocale(locale)) {
      setLocale(locale);
    }
  }

  return {
    ...toRefs(state),
    getActiveLocale,
    getLocale,
    isActiveLocale,
    isLocale,
    loadLocale,
    setLocale,
  };
}
