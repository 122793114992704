var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"main-menu bg-prasset-green-700 flex flex-row",on:{"mouseleave":function($event){return _vm.closeMenu()}}},[_c('button',{staticClass:"rounded-full bg-white w-6 h-6 flex items-center justify-center shadow absolute right-0 -mr-3 mt-20 focus:outline-none z-1",attrs:{"type":"button"},on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"leading-none transform",class:{
        'ri-pushpin-fill rotate-0 text-sm': _vm.menuOpen && _vm.openOnHover,
        'ri-arrow-drop-right-line rotate-0 text-xl': !_vm.menuOpen && !_vm.openOnHover,
        'ri-arrow-drop-right-line rotate-180 text-xl': _vm.menuOpen && !_vm.openOnHover,
      }})]),_c('div',{staticClass:"w-16 h-full pt-6 relative main-menu__menu flex flex-col justify-between",class:{ 'opened': _vm.menuOpen }},[_c('router-link',{staticClass:"mx-5 flex-shrink flex flex-row mb-12 items-center",attrs:{"to":{ name: 'dashboard' },"title":"Dashboard"}},[_c('div',{staticClass:"flex flex-row justify-center mr-4"},[_c('svg',{staticClass:"pointer-events-none",attrs:{"width":"20","height":"32","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"fill":"#FFF","fill-rule":"evenodd"}},[_c('path',{attrs:{"d":"M4.049 31.773a.74.74 0 01-.734-.635L.008 8.15a.74.74 0 01.396-.765L14.723.08a.74.74 0 011.053.471l4.107 15.6a.74.74 0 01-1.012.868l-14.95-6.493a.74.74 0 01.59-1.359l13.55 5.885-3.484-13.235L1.549 8.464l2.807 19.508 4.51-12.607a.741.741 0 011.396.5L4.746 31.281a.741.741 0 01-.697.49","fill":"#e2e8f0"}})])])]),_c('transition',{attrs:{"name":"menuIn"}},[(_vm.menuOpen)?_c('p',{staticClass:"text-white font-medium text-base truncate"},[_vm._v(_vm._s(_vm.project.name))]):_vm._e()])],1),_c('div',{staticClass:"ml-5 overflow-auto mb-6 flex-1 relative"},[_c('nav',{staticClass:"w-full flex flex-col justify-between whitespace-no-wrap"},[_c('MenuItem',{attrs:{"icon":"ri-community-line","item":{ name: _vm.$tuf('pim'), route: 'pim' },"subItems":[
            { name: _vm.$tuf('assets'), route: 'assets' },
            { name: _vm.$tuf('assetgroups'), route: 'assetgroups' } ]}}),_c('MenuItem',{attrs:{"icon":"ri-parent-line","item":{ name: _vm.$tuf('crm'), route: 'crm' },"subItems":[
            { name: _vm.$tuf('people'), route: 'people' }
          ]}}),_c('MenuItem',{attrs:{"icon":"ri-file-list-line","item":{ name: _vm.$tuf('presentation'), route: 'website' },"route":"website","active":_vm.presentationsActive,"subItems":[
            { name: _vm.$tuf('websites'), route: 'website', absolute: true },
            { name: _vm.$tuf('offer'), route: 'offer', absolute: true },
            { name: _vm.$tuf('interactive_maps'), route: 'mapsets', absolute: true },
            { name: _vm.$tuf('forms'), route: 'form', absolute: true } ]}})],1)]),_c('div',{staticClass:"flex pb-4",class:{
      'flex-col items-center justify-end': !_vm.menuOpen,
      'flex-row-reverse items-center justify-end': _vm.menuOpen
    }},[_c('router-link',{staticClass:"mx-4 focus:outline-none",class:{ 'mt-4': _vm.menuOpen },attrs:{"to":{ name: 'settings' },"title":_vm.$tuf('settings')},on:{"click":function($event){_vm.projectsOpen = !_vm.projectsOpen}}},[_c('i',{staticClass:"ri-settings-4-line text-prasset-gray-100 text-2xl"})]),_c('button',{staticClass:"mx-4 focus:outline-none",class:{ 'mt-8': !_vm.menuOpen, 'mt-4': _vm.menuOpen },attrs:{"type":"button","title":_vm.$tuf('projects')},on:{"click":function($event){_vm.projectsOpen = !_vm.projectsOpen}}},[_c('ProjectMenu',{staticClass:"cursor-pointer"})],1),_c('MeMenu',{staticClass:"mx-4",class:{ 'mt-8': !_vm.menuOpen, 'mt-4': _vm.menuOpen },attrs:{"title":_vm.$tuf('account')}})],1)],1),_c('div',{staticClass:"h-1 absolute inset-x-0 top-0 mt-32",on:{"mouseover":_vm.openMenu}}),_c('div',{staticClass:"h-1 absolute inset-x-0 bottom-0 mb-48",on:{"mouseover":_vm.openMenu}}),_c('div',{staticClass:"w-3 absolute inset-y-0 right-0 mt-32 mb-48",on:{"mouseover":_vm.openMenu}}),_c('transition',{attrs:{"name":"projectsIn"}},[_c('ProjectsMenu')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }