<template>
  <div class="passwordfield relative">
    <input class="bg-white placeholder-gray-600 font-base font-normal rounded border border-gray-300 py-2 px-4 w-full focus:outline-none focus:border-prasset-green-500"
      :class="{'text-input--error': (error && error.length > 0) }"
      type="password"
      :placeholder="placeholder"
      :required="required"
      :name="name"
      :value="value"
      :id="id"
      @input="$emit('input', $event.target.value)"
    />

    <InputValidator :errors="error" class="text-input__error" />
  </div>
</template>


<script>
import InputValidator from '@/components/alerts/InputValidator';

export default {
  components: {
    InputValidator,
  },

  props: {
    placeholder: {
      type: String,
    },

    required: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number],
      default: '',
    },

    name: {
      type: String,
      default: 'input',
    },

    id: {
      type: String,
      default: null,
    },

    error: {
      value: [Array, Object],
      default: () => {
        return null;
      },
    },
  },
};
</script>
