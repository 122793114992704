<template>
  <div>
    <RouterLink :to="{ name: item.route }" class="flex flex-row justify-start mt-8 hover:text-white focus:text-white focus:outline-none" :class="{ 'text-white': isActive, 'text-prasset-gray-200': !isActive }" :title="item.name">
      <i class="text-2xl leading-none mr-4" :class="icon" />
      <Transition name="menuIn">
        <span v-if="menuOpen" class="font-medium truncate">{{ item.name }}</span>
      </Transition>
    </RouterLink>
    <Transition v-if="subItems.length > 0" name="menuIn">
      <div class="main-menu__sub-menu" :class="{ 'active': isActive && menuOpen }">
        <ul class="ml-10 mt-2">
          <li class="py-1" v-for="(subitem, idx) in subItems" :key="idx">
            <RouterLink
              class="font-medium text-prasset-gray-300 main-menu__subitem hover:text-white focus:text-white"
              :to="getSubRoute(subitem)"
            >
              {{ subitem.name }}
            </RouterLink>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import { menuOpen } from '@/compositions/useNavigation';

export default {
  name: 'Menuitem',

  props: {
    icon: {
      type: String,
      default: null,
    },

    item: {
      type: Object,
      default: null,
    },

    active: {
      type: Boolean,
      default: undefined,
    },

    subItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  setup(props, { root }) {
    const isActive = computed(() => {
      return root.$route.name.indexOf(props.item.route) !== -1 || (props.active !== undefined && props.active);
    });

    function getSubRoute(subitem) {
      if (subitem.absolute) {
        return {
          name: subitem.route,
        };
      }

      return {
        name: `${props.item.route}-index`,
        params: {
          type: subitem.route,
        },
      };
    }

    return {
      menuOpen,
      isActive,
      getSubRoute,
    };
  },
};
</script>
