import App from '@/App.vue';
import Notifications from 'vue-notification';
import router from '@/providers/router';
import translations from '@/translations';
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueI18n from 'vue-i18n';
import '@/providers/i18n-addons';
import FieldsGeneratorField from '@/modules/core/views/components/FieldsGeneratorField';
import FieldsGeneratorRepeater from '@/modules/core/views/components/FieldsGeneratorRepeater';

Vue.use(VueCompositionAPI);
Vue.use(VueI18n);
Vue.use(Notifications);

Vue.config.productionTip = false;

// In order to use these components recursively we need te register them here.
Vue.component('FieldsGeneratorField', FieldsGeneratorField);
Vue.component('FieldsGeneratorRepeater', FieldsGeneratorRepeater);

const i18n = new VueI18n({
  locale: 'nl_NL',
  fallbackLocale: 'en_US',
  messages: translations,
});

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app');
