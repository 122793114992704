<template>
  <div class="overlay inset-0 bg-opacity-25" :class="{ 'absolute': !fixed, 'fixed': fixed }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
