<template>
  <div class="iconpicker relative block min-h-4 min-w-16" :class="optionClasses">
    <div
      tabindex="0"
      class="iconpicker__input relative flex items-center min-h-10 p-2 rounded border border-gray-300 bg-white focus:border-prasset-green-500 focus:outline-none"
      :class="{ 'text-input--error': this.error && this.error.length > 0 }"
      @click.stop="$refs.flyout.toggleFlyout"
      @keydown.space.prevent="$refs.flyout.toggleFlyout"
    >
      <div class="text-lg leading-none">
        <i :class="selected" />
      </div>
    </div>
    <Flyout ref="flyout">
      <VirtualList
        class="iconpicker__flyout absolute overflow-auto max-h-64 left-0 mt-1 w-min-full bg-white shadow-lg rounded-sm overflow-auto select-none"
        dataKey="id"
        :dataSources="iconset"
        :dataComponent="IconPickerItem"
        :estimateSize="30"
      />
    </Flyout>
    <InputValidator :errors="error" class="text-input__error" />
  </div>
</template>

<script>
import { toRefs, reactive, computed, onMounted } from '@vue/composition-api';
import VirtualList from 'vue-virtual-scroll-list';
import IconPickerItem from '@/components/field/IconPicker/Item';
import InputValidator from '@/components/alerts/InputValidator';
import RemixIconset from '@/manifests/remixicons.json';
import Flyout from '@/components/Flyout';

export default {
  name: 'IconPicker',

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  components: {
    VirtualList,
    InputValidator,
    Flyout,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    error: {
      value: [Array, Object],
      default: () => {
        return null;
      },
    },

    modelValue: {
      default: null,
    },
  },

  setup(props, { emit, refs }) {
    const state = reactive({
      selected: null,
    });

    function toggleSelected(value) {
      state.selected = (state.selected === value) ? null : value;
      emit('change', state.selected);
    }

    const iconset = computed(() => {
      const collection = RemixIconset || {};
      return Object.keys(collection).map(key => {
        return {
          id: key,
          text: collection[key],
          checked: key === state.selected,
        };
      });
    });

    const optionClasses = computed(() => {
      return {
        'iconpicker--disabled': props.disabled,
      };
    });

    function clickItem(id) {
      toggleSelected(id);
      refs.flyout.closeFlyout();
    }

    onMounted(() => {
      state.selected = props.modelValue;
    });

    return {
      ...toRefs(state),
      IconPickerItem,
      iconset,
      optionClasses,
      clickItem,
    };
  },
};
</script>

<style lang="scss">
.iconpicker {
    min-height: 2.65rem;

    &__input:after {
        content: '';

        @apply .absolute
               .right-0
               .bottom-0
               .top-0
               .text-xs
               .px-4
               .flex
               .items-center
               .pointer-events-none
               .bg-no-repeat
               .bg-center;

        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="5"%3E%3Cg transform="translate(-302 -48)"%3E%3Cpath d="M306 52.667l-4-4h8z" fill="%231A282F" /%3E%3C/g%3E%3C/svg%3E%0A');
    }

    &--readonly {
        @apply .bg-gray-100;

        &:after {
            @apply .bg-gray-100;
        }
    }

    &--disabled {
        @apply .bg-gray-100
               .text-gray-300;

        &:after {
            @apply .bg-gray-100;
        }
    }
}
</style>
