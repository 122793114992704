export default {
  account: 'account',
  actions: 'actions',
  add_offer: 'add offer',
  add_contract: 'add contract',
  add_new_component: 'add new component',
  add_page: 'add page',
  add_project: 'add project',
  add_user: 'add user',
  add_view: 'add view',
  are_you_sure_you_want_to_delete_these_items: 'are you sure you want to delete these items?',
  are_you_sure_you_want_to_delete_this_item: 'are you sure you want to delete this item?',
  are_you_sure_you_want_to_leave_without_saving: 'are you sure you want to leave without saving?',
  artboard: 'artboard',
  asset: 'asset',
  assetgroups: 'asset groups',
  assets: 'assets',
  assettypes: 'asset types',
  back_to_login: 'back to login',
  busy_fetching_data: 'busy fetching data',
  cancel: 'cancel',
  change_notification: 'change notification',
  change_password: 'change password',
  change_profile_photo: 'change profile photo',
  choose_other_component: 'choose other component',
  collections: 'collections',
  confirm: 'confirm',
  crm: 'CRM',
  current_password: 'current password',
  dark_logo: 'dark logo',
  delete: 'delete',
  description_crm_people: 'The place where new relations and leads can be added.',
  description_form_forms: 'Prepare a questionnaire for your visitors with forms.',
  description_form_formsubmissions: 'The place where visitor submission forms are registered.',
  description_form_notifications: 'Manage who should receive email notifications when submitting a form.',
  description_map_collections: 'Group created views into a collection.',
  description_map_views: 'Upload impressions, photos or floor plans of your property.',
  description_offer_collections: 'Configure the presentation options for your specific real estate offer.',
  description_pim_assetgroups: 'Group assets hierarchically by creating asset groups.',
  description_pim_assets: 'Manage and create all the objects you want to rent or sell.',
  description_pim_assettypes: 'Distinguish between different objects using asset types that you can name yourself.',
  description_settings_projects: 'Create, manage or navigate to a project.',
  description_settings_users: 'Add new participants to your project.',
  description_website_websites: 'Create websites to present your real estate.',
  domains: 'domains',
  edit_artboard: 'edit artboard',
  edit_asset: 'edit asset',
  edit_assetgroup: 'edit asset group',
  edit_assettype: 'edit asset type',
  edit_collection: 'edit collection',
  edit_form: 'edit form',
  edit_notification: 'edit notification',
  edit_person: 'edit person',
  edit_project: 'edit project',
  edit_user: 'edit user',
  edit_view: 'edit view',
  edit_website: 'edit website',
  edit_offer: 'edit offer',
  email_verified_sentence: 'your email address has been confirmed, enter a password that you like to use to log in to Prasset',
  emailaddress: 'emailaddress',
  end_date: 'end date',
  enter_a_new_password: 'enter a new password',
  error: 'error',
  export_data_as_csv: 'export data as csv',
  female: 'female',
  formnotifications: 'notifications', // same as notifications
  forms: 'forms',
  formsubmissions: 'submissions',     // same as submissions
  go_to_crm: 'go to CRM',
  go_to_pim: 'go to PIM',
  help_and_contact: 'help & contact',
  housing: 'housing',
  i_forgot_my_password: 'I forgot my password',
  interactive_maps: 'interactive maps',
  invalid_credentials: 'invalid credentials',
  item_deleted_successfully: 'item deleted successfully',
  item_not_found_question_type_to_search: 'item not found? type to search',
  items_deleted_successfully: 'items deleted successfully',
  light_logo: 'light logo',
  login: 'login',
  logout: 'logout',
  male: 'male',
  name: 'name',
  new_asset: 'new asset',
  new_assetgroup: 'new asset group',
  new_assettype: 'new asset type',
  new_collection: 'new collection',
  new_component: 'new component',
  new_form: 'new form',
  new_notification: 'new notification',
  new_page: 'new page',
  new_password: 'new password',
  new_person: 'new person',
  new_project: 'new project',
  new_user: 'new user',
  new_website: 'new website',
  new_offer: 'new offer',
  no_target_set: 'no target set',
  not_available_short: 'n/a',
  notice: 'notice',
  notifications: 'notificaties',
  offer: 'offer',
  other_projects: 'other projects',
  page_number: 'page {number}',
  page_settings: 'page settings',
  pages: 'pages',
  password_set_successfully: 'password set successfully',
  password: 'password',
  people: 'people',
  person: 'person',
  pim: 'Objecten',
  presentation: 'presentation',
  preview: 'preview',
  privacy: 'privacy',
  profile_and_settings: 'profile & settings',
  profile: 'profile',
  projects: 'projects',
  recent_changed_assets: 'recent changed assets',
  recover_password: 'recover password',
  repeat_the_new_password: 'repeat the new password',
  request_for_additional_modules: 'request for additional modules',
  reset_filters: 'reset filters',
  reset_your_password: 'reset your password',
  residents: 'residents',
  save_and_back: 'save & back',
  save: 'save',
  saved: 'saved',
  selected_project: 'selected project',
  set_password: 'set password',
  setting_password_failed: 'setting password failed',
  settings: 'settings',
  something_went_wrong_while_fetching_data: 'something went wrong while fetching data',
  start_date: 'start date',
  submissions: 'submissions',
  success: 'success',
  theme: 'thema',
  total_items_found: 'no items found | 1 item found | {count} items found',
  total_selected: '{count} selected',
  unknown_address: 'unknown address',
  users: 'users',
  verification_token_not_found: 'verification token not found',
  views: 'views',
  website_settings: 'website settings',
  website_visitors_last_week: 'website visitors last week',
  websites: 'websites',
  yes_delete: 'yes, delete',
};
