<template>
  <div class="markdown" v-html="html"></div>
</template>

<script>
import { computed } from '@vue/composition-api';
import marked from 'marked';

marked.setOptions({
  breaks: true,
});

export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const html = computed(() => {
      return marked(props.content);
    });

    return {
      html,
    };
  },
};
</script>
