<template>
  <div class="data-generator-entry flex flex-col" :class="{ 'input-error': errors && !!errors[field.name] }">
    <section v-if="field.type === 'section'">
      <h2 v-if="field.title" class="text-xl font-semibold text-prasset-green-500">{{ field.title }}</h2>
      <MarkdownContent v-if="field.content" class="text-sm text-prasset-gray-800 mt-2" :content="field.content" />
    </section>

    <label
      v-if="!['check', 'checkbox', 'radio', 'radiobutton', 'section', 'repeater'].includes(field.type)"
      class="text-sm text-prasset-green-800 mb-2 block"
      :for="uuid"
    >
      {{ field.label }}
    </label>

    <FieldsGeneratorRepeater
      v-if="field.type === 'repeater'"
      :fields="field.fields"
      :config="field.config || { }"
      :parent="field"
      :data="data"
      :errors="errors"
      :entryId="entryId"
      :entryType="entryType"
      :fileCollection="fileCollection"
    />

    <TextField
      v-if="['text', 'email', 'tel'].includes(field.type)"
      :id="uuid"
      :type="field.type"
      :placeholder="field.placeholder"
      :name="field.name"
      :error="errors[field.name]"
      :disabled="field.disabled || !enabledIf(field)"
      :readonly="field.readonly || !enabledIf(field)"
      :value="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <DecimalField
      v-if="field.type === 'decimal'"
      :id="uuid"
      :type="field.type"
      :placeholder="field.placeholder"
      :name="field.name"
      :error="errors[field.name]"
      :disabled="field.disabled || !enabledIf(field)"
      :readonly="field.readonly || !enabledIf(field)"
      :value="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <NumberField
      v-if="field.type === 'number'"
      :id="uuid"
      :type="field.type"
      :placeholder="field.placeholder"
      :name="field.name"
      :error="errors[field.name]"
      :disabled="field.disabled || !enabledIf(field)"
      :readonly="field.readonly || !enabledIf(field)"
      :value="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <TextareaField
      v-if="field.type === 'textarea'"
      :id="uuid"
      :rows="field.rows"
      :type="field.type"
      :placeholder="field.placeholder"
      :name="field.name"
      :error="errors[field.name]"
      :readonly="field.readonly || !enabledIf(field)"
      :disabled="field.disabled || !enabledIf(field)"
      :value="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <DateField
      v-if="field.type === 'date'"
      :name="field.name"
      :value="data[field.name]"
      :placeholder="field.placeholder"
      :config="field.config || {}"
      :error="errors[field.name]"
      :modelValue="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <DateRangeField
      v-if="field.type === 'date-range'"
      :name="field.name"
      :placeholder="field.placeholder"
      :config="field.config || {}"
      :modelValue="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <ToggleField
      v-if="field.type === 'toggle'"
      :name="field.name"
      :value="data[field.name]"
      :placeholder="field.placeholder"
      :modelValue="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <CheckField
      v-if="['check', 'checkbox'].includes(field.type)"
      :name="field.name"
      :value="data[field.name]"
      :modelValue="getValue(field)"
      @change="onValueChanged(field, $event)"
    >{{ field.label }}</CheckField>

    <template v-if="['radio', 'radiobutton'].includes(field.type)">
      <RadioField
        class="mb-1"
        v-for="(label, key) in field.options"
        :key="key"
        :name="field.name"
        :value="key"
        :modelValue="getValue(field)"
        @change="onValueChanged(field, $event)"
      >{{ label }}</RadioField>
    </template>

    <HtmlField
      v-if="field.type === 'html'"
      :name="field.name"
      :placeholder="field.placeholder"
      :disabled="field.disabled"
      :value="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <FilesField
      v-if="field.type === 'files'"
      :name="field.name"
      :entryId="entryId"
      :entryType="entryType"
      :disabled="field.disabled"
      :collection="field.name"
      :config="field.config"
      :subSelection="getMediaSubselection(field)"
      :existingFiles="getMediaCollection(field)"
      :modelValue="getMediaIds(field)"
      @change="onMediaChanged(field, $event)"
    />

    <SelectField
      v-if="field.type === 'select'"
      :name="field.name"
      :options="field.options || null"
      :endpoint="fieldEndpoint(field)"
      :error="errors[field.name]"
      :disabled="!enabledIf(field)"
      :value="getValue(field)"
      @change="onValueChanged(field, $event)"
    />

    <MultiSelectField
      v-if="field.type === 'multiselect'"
      :name="field.name"
      :options="field.options || null"
      :placeholder="field.placeholder"
      :endpoint="fieldEndpoint(field)"
      :error="errors[field.name]"
      :disabled="!enabledIf(field)"
      :modelValue="getValue(field)"
      @change="onValueChanged(field, $event)"
    />

    <SingleSelectField
      v-if="field.type === 'singleselect'"
      :name="field.name"
      :options="field.options || null"
      :placeholder="field.placeholder"
      :endpoint="fieldEndpoint(field)"
      :error="errors[field.name]"
      :disabled="!enabledIf(field)"
      :modelValue="getValue(field)"
      @change="onValueChanged(field, $event)"
    />

    <IconPicker
      v-if="field.type === 'iconpicker'"
      :name="field.name"
      :error="errors[field.name]"
      :disabled="!enabledIf(field)"
      :multiple="field.multiple"
      :modelValue="getValue(field)"
      @change="onValueChanged(field, $event)"
    />

    <ColorField
      v-if="field.type === 'color'"
      :name="field.name"
      :config="field.config || {}"
      :value="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <MarkdownField
      v-if="field.type === 'markdown'"
      :name="field.name"
      :config="field.config || {}"
      :value="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <LinkField
      v-if="field.type === 'link'"
      :name="field.name"
      :error="errors[field.name]"
      :config="field.config || {}"
      :value="getValue(field)"
      @input="onValueChanged(field, $event)"
    />

    <div v-if="field.hint && !['repeater'].includes(field.type)" class="hint">
      {{ field.hint }}
    </div>
  </div>
</template>

<script>
import { set } from '@vue/composition-api';
import { generateUUID/*, getNestedObject*/ } from '@/providers/helpers';
import useFieldConditions from '@/compositions/useFieldConditions';
import CheckField from '@/components/field/Check';
import ColorField from '@/components/field/Color';
import DateField from '@/components/field/Date';
import DateRangeField from '@/components/field/DateRange';
import DecimalField from '@/components/field/Decimal';
import FilesField from '@/components/field/Files';
import HtmlField from '@/components/field/HtmlEditor';
import IconPicker from '@/components/field/IconPicker';
import LinkField from '@/components/field/Link';
import MarkdownContent from '@/components/MarkdownContent';
import MarkdownField from '@/components/field/Markdown';
import MultiSelectField from '@/components/field/MultiSelect';
import NumberField from '@/components/field/Number';
import RadioField from '@/components/field/Radio';
import SelectField from '@/components/field/Select';
import SingleSelectField from '@/components/field/SingleSelect';
import TextareaField from '@/components/field/Textarea';
import TextField from '@/components/field/Text';
import ToggleField from '@/components/field/Toggle';

export default {
  name: 'FieldsGeneratorField',

  components: {
    CheckField,
    ColorField,
    DateField,
    DateRangeField,
    DecimalField,
    FilesField,
    HtmlField,
    IconPicker,
    LinkField,
    MarkdownContent,
    MarkdownField,
    MultiSelectField,
    NumberField,
    RadioField,
    SelectField,
    SingleSelectField,
    TextareaField,
    TextField,
    ToggleField,
    // FieldsGeneratorRepeater, // registered in main.js
  },

  props: {
    field: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    errors: {
      type: [Array, Object],
      default: () => ({}),
    },

    parent: {
      type: Object,
      default: null,
    },

    parentIndex: {
      type: Number,
      default: 0,
    },

    entryType: {
      type: String,
      default: null,
    },

    entryId: {
      type: String,
      default: null,
    },

    fileCollection: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const { fieldConditionsFor } = useFieldConditions();

    function onValueChanged(field, value) {
      if (props.parent) {
        props.data[props.parent.name][props.parentIndex][field.name] = value;
      } else {
        set(props.data, field.name, value);
      }
    }

    function getValue(field) {
      if (props.parent) {
        if (props.data[props.parent.name][props.parentIndex][field.name] === undefined && field.default !== undefined) {
          return field.default;
        }

        return props.data[props.parent.name][props.parentIndex][field.name];
      }

      if (props.data[field.name] === undefined && field.default !== undefined) {
        return field.default;
      } else {

        //if the name of the field includes "." it means it's a nested object.
        //if so, itertate through the fields to map the correct data.
        // if (props.field.data_map && props.field.data_map.includes('.')) {
        //
        //   const keys = props.field.data_map.split('.');
        //
        //   return getNestedObject(props.data, keys);
        // }

        return props.data[field.name];
      }
    }

    function getMediaCollection(field) {
      return props.fileCollection.filter(file => file.collection === field.name);
    }

    function getMediaIds(field) {
      return (props.fileCollection || []).filter(file => file.collection === field.name).map(file => file.id);
    }

    // used for media in repeated fields
    function getMediaSubselection(field) {
      if (props.parent) {
        return props.data[props.parent.name][props.parentIndex][field.name] || [];
      }
      return null;
    }

    const onMediaChanged = (field, fileIds) => {
      if (props.parent) {
        // attach fileIds as property of nested object.
        props.data[props.parent.name][props.parentIndex][field.name] = fileIds;
      }
    };

    return {
      onValueChanged,
      onMediaChanged,
      getValue,
      getMediaIds,
      getMediaCollection,
      getMediaSubselection,
      enabledIf: field => fieldConditionsFor('enabledIf', props.data, field),
    };
  },

  computed: {
    fieldEndpoint() {
      return field => {
        if (field.endpoint) {
          if (field.endpoint[0] !== '/') {
            return `projects/${this.$route.params.project_id}/${field.endpoint}`;
          } else {
            return field.endpoint;
          }
        }
        return undefined;
      };
    },

    uuid() {
      return generateUUID();
    },
  },
};
</script>
