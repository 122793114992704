<template>
  <Modal ref="modal">
    <form method="post" @submit.prevent="save">
      <h1 class="text-xl font-medium text-prasset-gray-900 mb-2">{{ $tuf('change_password') }}</h1>
      <div class="grid grid-cols-1 gap-6">
        <div>
          <label for="old_password" class="text-sm text-prasset-green-800 mb-2 block">{{ $tuf('current_password') }}</label>
          <PasswordField id="old_password" v-model="oldPassword" :error="errors.old_password" :placeholder="$tuf('current_password')" />
        </div>
        <div>
          <label for="new_password" class="text-sm text-prasset-green-800 mb-2 block">{{ $tuf('new_password') }}</label>
          <PasswordField id="new_password" v-model="newPassword" :error="errors.new_password" :placeholder="$tuf('new_password')" />
        </div>
      </div>

      <button type="submit" class="sr-only"></button>
    </form>
    <template v-slot:footer>
      <button type="button" class="button button--outlined ml-2" @click="hide">{{ $tuf('cancel') }}</button>
      <button type="button" class="button button--opague  ml-2" @click="save">{{ $tuf('save') }}</button>
    </template>
  </Modal>
</template>

<script>
import { toRefs, reactive } from '@vue/composition-api';
import useProfile from '@/compositions/useProfile';
import Modal from '@/modules/core/views/components/Modal';
import PasswordField from '@/components/field/Password';

export default {
  name: 'ChangePasswordModal',

  components: {
    Modal,
    PasswordField,
  },

  setup(props, { refs, root }) {
    const { changePassword } = useProfile();

    const state = reactive({
      oldPassword: null,
      newPassword: null,
      errors: {},
    });

    async function save() {
      try {
        state.errors = {};
        await changePassword(state.oldPassword, state.newPassword);
        refs.modal.hide();
        root.$notify({
          type: 'success',
          title: root.$tuf('success'),
          text: 'Wachtwoord is aangepast',
        });
      } catch (error) {
        state.errors = error.response.data.errors;
        root.$notify({
          type: 'error',
          title: root.$tuf('error'),
          text: 'Wachtwoord aanpassen is mislukt',
        });
      }
    }

    const show = () => refs.modal.show();
    const hide = () => refs.modal.hide();

    return {
      ...toRefs(state),
      save,
      show,
      hide,
    };
  },
};
</script>
