export default [
  {
    projectScope: true,
    name: 'map',
    path: 'maps',
    redirect: to => ({
      name: 'maps',
      params: {
        project_id: to.params.project_id,
      },
    }),
  },
  {
    projectScope: true,
    name: 'maps',
    path: 'maps/maps',
    component: () => import('@/modules/map/views/templates/Maps' /* webpackChunkName: "map.maps" */),
    children: [
      {
        projectScope: true,
        name: 'map-create',
        path: 'create',
        component: () => import('@/modules/map/views/templates/MapCreate' /* webpackChunkName: "maps.map.create" */),
      },
    ],
  },
  {
    projectScope: true,
    name: 'mapsets',
    path: 'maps/mapsets',
    component: () => import('@/modules/map/views/templates/Mapsets' /* webpackChunkName: "maps.mapsets" */),
    children: [
      {
        projectScope: true,
        name: 'mapsets-create',
        path: 'create',
        component: () => import('@/modules/map/views/templates/MapsetCreate' /* webpackChunkName: "maps.mapsets.create" */),
      },
      {
        projectScope: true,
        name: 'mapsets-edit',
        path: ':entry/edit',
        component: () => import('@/modules/map/views/templates/MapsetEdit' /* webpackChunkName: "maps.mapsets.edit" */),
      },
    ],
  },
  {
    projectScope: true,
    name: 'map-pointers',
    path: 'maps/maps/:map_id/pointers',
    component: () => import('@/modules/map/views/templates/Mappointers' /* webpackChunkName: "maps.mappointers" */),
    children: [
      {
        projectScope: true,
        name: 'map-edit',
        path: 'edit',
        component: () => import('@/modules/map/views/templates/MapEdit' /* webpackChunkName: "maps.map.edit" */),
      },
      {
        projectScope: true,
        name: 'map-mappointer-edit',
        path: ':mappointer_id/edit',
        component: () => import('@/modules/map/views/templates/MappointerEdit' /* webpackChunkName: "maps.mappointers.edit" */),
      },
      {
        projectScope: true,
        name: 'map-create-in-mappointers-view',
        path: 'create',
        component: () => import('@/modules/map/views/templates/MapCreate' /* webpackChunkName: "maps.map.create" */),
      },
    ],
  },
];
