<template>
  <div class="colorfield">
    <Swatches
      :show-fallback="true"
      :swatch-size="30"
      :trigger-style="{ width: '2rem', height: '2rem', borderRadius: '100%', borderWidth: '1px' }"
      v-model="internalValue"
    />
  </div>
</template>

<script>
import Swatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';

export default {
  components: {
    Swatches,
  },

  props: {
    name: {
      type: String,
      default: 'input',
    },

    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      internalValue: '',
    };
  },

  mounted() {
    this.internalValue = this.value;
  },

  watch: {
    internalValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>
