<template>
    <label class="relative block">
        <div class="selectfield flex bg-white border border-gray-300 rounded items-center overflow-hidden relative text-prasset-gray-800" :class="optionClasses">
          <select
              :name="name"
              :id="id"
              :disabled="disabled"
              :readonly="readonly"
              v-model="selectedValue"
              @change="onChange"
              class="flex-1 w-full py-2 pl-4 pr-10 bg-transparent appearance-none focus:outline-none">
              <option v-if="optional" :value="null">-</option>
              <option v-for="(name, value) in optionValues" :key="value" :value="value">{{ name }}</option>
          </select>
        </div>
        <InputValidator :errors="error" class="text-input__error" />
    </label>
</template>

<script>
import Api from '@/providers/api';
import InputValidator from '@/components/alerts/InputValidator';

export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },

  components: {
    InputValidator,
  },

  props: {
    name: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    optional: {
      type: Boolean,
      default: true,
    },

    value: {
      default: null,
    },

    options: {
      type: [Array, Object] ,
      default: null,
    },

    endpoint: {
      type: String,
      default: undefined,
    },

    error: {
      value: [Array, Object],
      default: () => {
        return null;
      },
    },

    modelValue: {
      default: undefined,
    },
  },

  data() {
    return {
      selectedValue: '',
      optionValues: [],
    };
  },

  computed: {
    optionClasses() {
      return {
        'selectfield--disabled': this.disabled,
        'selectfield--readonly': this.readonly,
        'text-input--error': this.error && this.error.length > 0,
      };
    },
  },

  mounted() {
    if (this.modelValue !== undefined) {
      this.selectedValue = this.modelValue;
    } else {
      this.selectedValue = this.value;
    }

    this.optionValues = this.options;

    if (this.endpoint) {
      this.fetchFromEndpoint();
    }
  },

  watch: {
    value(newValue) {
      this.selectedValue = newValue;
    },

    options(options) {
      this.optionValues = options;
    },
  },

  methods: {
    onChange(event) {
      const value = event.target.value;
      this.$emit('change', value);
    },

    fetchFromEndpoint(page = 1) {
      Api().get(this.endpoint, { params: { page, per_page: 99999 } }).then(response => {
        this.optionValues = {};
        response.data.data.map(item => {
          this.optionValues[item.id] = item.name;
        });
      });
    },
  },
};
</script>

<style lang="scss">
.selectfield {
    &:after {
        content: '';

        @apply .absolute
               .right-0
               .bottom-0
               .top-0
               .text-xs
               .bg-white
               .px-4
               .flex
               .items-center
               .pointer-events-none
               .bg-no-repeat
               .bg-center;

        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="5"%3E%3Cg transform="translate(-302 -48)"%3E%3Cpath d="M306 52.667l-4-4h8z" fill="%231A282F" /%3E%3C/g%3E%3C/svg%3E%0A');
    }

    &:focus-within {
        @apply .border-prasset-green-500;
        z-index: 1;
    }

    &--readonly {
        @apply .bg-gray-100;

        &:after {
            @apply .bg-gray-100;
        }
    }

    &--disabled {
        @apply .bg-gray-100
               .text-gray-300;

        &:after {
            @apply .bg-gray-100;
        }
    }
}
</style>
