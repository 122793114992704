<template>
  <div class="flex justify-between w-full cursor-pointer">
    <div class="w-20 flex-shrink-0 relative overflow-hidden pt-2 pb-2 pr-4">
      <img v-if="!uploading && isValidImage && !isConfidential && !failed" :src="preview" class="max-w-full max-h-full" />
      <div v-if="!uploading && isConfidential && !failed" class="max-w-full max-h-full flex justify-center text-3xl text-gray-400"><i class="ri-eye-off-line" /></div>
      <img v-if="uploading" src="@/assets/loading.gif" class="max-w-full max-h-full p-2" />
    </div>
    <div class="flex-grow overflow-auto py-2">
      <a v-if="!isConfidential" :href="fileUrl" target="_blank" class="truncate text-prasset-gray-900 hover:underline">
        {{ file.name || file.id }}
      </a>
      <div v-if="isConfidential" class="truncate text-prasset-gray-900">
        {{ file.name || file.id }}
      </div>
      <div class="text-xs text-prasset-gray-800">
        {{ fileSizeInMB }} | extensie: {{ extension }}
        <span v-if="failed"> | <span class="text-prasset-red-600">bestand te groot of incorrect formaat</span></span>
      </div>
    </div>
    <div class="flex-shrink-0 pl-3 border-l border-gray-200">
      <button @click="removeFile()" type="button" class="button button--clear button--small text-sm mt-2">
        <i class="icon-delete" />
      </button>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, computed, onMounted } from '@vue/composition-api';
import filesize from 'filesize';
import Api from '@/providers/api';
import EventBus from '@/eventbus';

export default {
  name: 'FileField',

  props: {
    file : {
      type: [File, Object],
      default: null,
    },

    isUploaded: {
      type: Boolean,
      default: true,
    },

    isConfidential: {
      type: Boolean,
      default: false,
    },

    meta: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit, root }) {
    const projectId = root.$route.params.project_id;

    const state = reactive({
      uploading: false,
      failed: false,
      imageId: null,
      fileUrl: (props.isUploaded ? props.file.urls.original : null),
    });

    const fileType = computed(() => {
      return props.file.type || props.file.mime_type;
    });

    const isValidImage = computed(() => {
      return fileType.value.includes('image/') ? true : false;
    });

    const extension = computed(() => {
      return fileType.value.split('/').pop();
    });

    const fileSizeInMB = computed(() => {
      return filesize((props.file.size || props.file.original_size));
    });

    const preview = computed(() => {
      return props.file.urls && props.file.urls.original ? props.file.urls.original : null;
    });

    function uploadFile() {
      const formData = new FormData();

      formData.append('model_type', props.meta.entryType);
      formData.append('model_id', props.meta.entryId);
      formData.append('collection', props.meta.collection);
      formData.append('file', props.file);

      if (props.isConfidential) {
        formData.append('confidential', true);
      }

      state.uploading = true;

      Api().post(`projects/${projectId}/media`, formData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        state.imageId = response.data.data.id;
        state.fileUrl = response.data.data.urls.original;
        emit('uploaded', response.data.data, props.file);
      }).catch(() => {
        state.failed = true;
        emit('failed', props.file);
      }).finally(() => {
        state.uploading = false;
      });
    }

    function removeFile() {
      EventBus.$emit('confirm:open', {
        title: 'Let op',
        main: `Weet je zeker dat je deze bestand wilt verwijderen? Het verwijderen van bestanden kan niet ongedaan worden gemaakt.`,
        confirmButton: 'Ja, verwijderen',
        cancelButton: 'Annuleren',
        async onConfirm() {
          if (!state.failed) {
            await Api().delete(`projects/${projectId}/media/${props.isUploaded ? props.file.id : state.imageId}`);
          }
          emit('removed', props.isUploaded, props.file);
        },
      });
    }

    // function createImagePreview(file) {
    //   const reader = new FileReader();
    //
    //   reader.onload = (event) => {
    //     state.previews.push(event.target.result);
    //   };
    //
    //   reader.readAsDataURL(file);
    // }

    onMounted(() => {
      if (!props.isUploaded && props.meta) {
        uploadFile();
      }
    });

    return {
      ...toRefs(state),
      fileType,
      isValidImage,
      extension,
      fileSizeInMB,
      uploadFile,
      removeFile,
      preview,
    };
  },
};
</script>
