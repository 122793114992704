<template>
  <div>
    <RouterView id="app" />
    <notifications position="bottom right" />
    <ModalRoot />
  </div>
</template>

<script>
import ModalRoot from '@/components/modals/ModalRoot';
import useLocale from '@/compositions/useLocale';

export default {
  name: 'App',

  components: {
    ModalRoot,
  },

  setup() {
    const { loadLocale } = useLocale();

    loadLocale();
  },
};
</script>

<style lang="scss">
@import "@/scss/all";

#app {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
