<template>
  <div>
    <div class="flex justify-between mb-2">
      <div>
        <h2 class="text-xl font-semibold text-prasset-green-500">
          {{ parent.label }} ({{ totals }})
        </h2>
        <div v-if="parent.hint || rules" class="hint">
          {{ parent.hint }}
          <span v-if="rules"> ({{ rules }})</span>
        </div>
      </div>
      <div>
        <button type="button" class="button button--opague button--small text-sm" @click.prevent="addRow" :disabled="!enabled">
          Toevoegen
        </button>
      </div>
    </div>

    <Draggable v-model="data[parent.name]" handle=".handle">
      <div v-for="(row, i) in rows" :key="i" class="my-1">
        <div class="flex items-center justify-between bg-prasset-green-400 hover:bg-prasset-green-500 rounded">
          <button type="button" class="handle flex w-full text-left text-white rounded focus:outline-none" @click.prevent="toggle(i)">
            <i class="ri-arrow-up-down-line no-underline ml-2 opacity-50"></i>
            <div class="max-w-full px-2 truncate">{{ getRowLabel(i) }}</div>
          </button>
          <button type="button" class="button button--clear button--small button--clamp text-xs text-white ml-4" title="regel verwijderen" @click.prevent="removeRow(i)">
            <i class="icon-delete" />
          </button>
        </div>
        <div class="grid grid-cols-6 gap-6 mb-4 w-full bg-gray-200 p-4" :class="{ hidden: openState !== i }">
          <FieldsGeneratorField
            v-for="(field, idx) in fields"
            :errors="errors"
            :field="field"
            :data="data"
            :key="idx"
            :parent="parent"
            :parentIndex="i"
            :entryId="entryId"
            :entryType="entryType"
            :fileCollection="fileCollection"
            :style="{ 'z-index': fields.length - idx }"
            :class="`col-span-${field.colSpan || field.colspan || 6} flex flex-col`"
          />
        </div>
      </div>
    </Draggable>
  </div>
</template>

<script>
/*
tailwind-purge:
col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6
*/

import { computed, reactive, toRefs } from '@vue/composition-api';
import Vue from 'vue';
import Draggable from 'vuedraggable';

export default {
  name: 'FieldsGeneratorRepeater',

  components: {
    Draggable,
  //   FieldsGeneratorField, // registered in main.js
  },

  props: {
    fields: {
      type: Array,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    config: {
      type: Object,
      default: () => ({}),
    },

    errors: {
      type: Object,
      default: () => ({}),
    },

    parent: {
      type: Object,
      default: null,
    },

    entryType: {
      type: String,
      default: null,
    },

    entryId: {
      type: String,
      default: null,
    },

    fileCollection: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const state = reactive({
      openState: -1,
    });

    const rows = computed(() => props.data[props.parent.name] || []);

    function addRow() {
      const fieldNames = props.fields.map(field => [field.name, null]);

      if (!props.data[props.parent.name]) {
        Vue.set(props.data, props.parent.name, []);
      }

      props.data[props.parent.name].push(Object.fromEntries(fieldNames));
      state.openState = props.data[props.parent.name].length - 1;
    }

    function removeRow(index) {
      props.data[props.parent.name].splice(index, 1);
    }

    function getRowLabel(i) {
      const sourceFields = props.fields.filter(field =>
        ['text', 'textarea', 'link'].includes(field.type)
      );

      let label = null;

      if (sourceFields.length > 0 && props.data[props.parent.name]) {
        sourceFields.forEach(sourceField => {
          if (!label && props.data[props.parent.name][i][sourceField.name] && props.data[props.parent.name][i][sourceField.name] != '') {
            switch (sourceField.type) {
              case 'link':
                label = props.data[props.parent.name][i][sourceField.name].label;
                break;
              default:
                label = props.data[props.parent.name][i][sourceField.name];
            }
          }
        });
      }

      if (!label) {
        label = props.config.defaultLabel || 'Nieuw item';
      }

      return label;
    }

    function toggle(i) {
      if (state.openState === i) {
        state.openState = -1;
      } else {
        state.openState = i;
      }
    }

    const totals = computed(() => {
      if (props.config.max) {
        return `${rows.value.length} van ${props.config.max}`;
      }
      return `${rows.value.length}`;
    });

    const enabled = computed(() => {
      if (props.config.max && rows.value.length >= props.config.max) {
        return false;
      }
      return true;
    });

    const rules = computed(() => {
      const rules = [];
      if (props.config.min) {
        rules.push(`minimaal ${props.config.min}`);
      }
      if (props.config.max) {
        rules.push(`maximaal ${props.config.max}`);
      }
      if (rules.length > 0) {
        return rules.join(', ');
      }
      return null;
    });

    return {
      ...toRefs(state),
      addRow,
      enabled,
      getRowLabel,
      removeRow,
      rows,
      rules,
      toggle,
      totals,
    };
  },
};
</script>
