<template>
  <div v-if="loaded">
    <MainMenu class="z-2" />
    <RouterView class="project-main z-1" :class="{'opened': menuOpen }" />
    <PortalTarget name="modals" slim />
    <PortalTarget name="crudsidebar" slim />
  </div>
  <div v-else class="project-loading">
    <img src="@/assets/loading.gif">
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api';
import { fetchProjects, getProject } from '@/compositions/useProjects';
import { menuOpen } from '@/compositions/useNavigation';
import { PortalTarget } from 'portal-vue';
import MainMenu from '@/modules/core/views/components/MainMenu';

export default {
  name: 'Project',

  components: {
    MainMenu,
    PortalTarget,
  },

  setup(props, vm) {
    const loaded = ref(false);
    const router = ref(vm.root.$options.router);
    const project_id = ref(vm.root.$options.router.currentRoute.params.project_id);
    const path = computed(() => vm.root.$options.router.currentRoute.params.project_id);

    const loadProject = (id) => {
      loaded.value = false;
      getProject(id).then(() => {
        loaded.value = true;
      }).catch(() => {
        loaded.value = false;
        router.value.push({ name: '404' });
      });
    };

    fetchProjects();
    loadProject(project_id.value);

    watch(path, () => {
      loadProject(vm.root.$options.router.currentRoute.params.project_id);
    });

    return {
      project_id,
      loaded,
      menuOpen,
    };
  },
};
</script>

<style lang="scss">
  .project-loading {
    background-color: #1a282f;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
  }

  .project-main {
    transition:
      margin-left 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
      width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    overflow: auto;
    height: 100%;
    margin-left: 4rem;
    position: relative;

    &.opened {
      margin-left: 17rem;
    }
  }
</style>
