export default {
  account: 'account',
  actions: 'acties',
  add_offer: 'aanbod toevoegen',
  add_contract: 'contract toevoegen',
  add_new_component: 'nieuw component toevoegen',
  add_page: 'pagina toevoegen',
  add_project: 'project toevoegen',
  add_user: 'gebruiker toevoegen',
  add_view: 'aanzicht toevoegen',
  are_you_sure_you_want_to_delete_these_items: 'weet je zeker dat je deze items wilt verwijderen?',
  are_you_sure_you_want_to_delete_this_item: 'weet je zeker dat je dit item wil verwijderen?',
  are_you_sure_you_want_to_leave_without_saving: 'weet je zeker dat je terug wilt gaan zonder op te slaan?',
  artboard: 'tekengebied',
  asset: 'asset',
  assetgroups: 'categorieën',
  assets: 'alle objecten',
  assettypes: 'assettypen',
  back_to_login: 'terug naar inloggen',
  busy_fetching_data: 'bezig met ophalen van gegevens',
  cancel: 'annuleren',
  change_notification: 'melding wijzigen',
  change_password: 'wachtwoord wijzigen',
  change_profile_photo: 'profiel foto wijzigen',
  choose_other_component: 'ander component kiezen',
  collections: 'Kaarten',
  confirm: 'bevestigen',
  crm: 'CRM',
  current_password: 'huidige wachtwoord',
  dark_logo: 'logo donker',
  delete: 'verwijderen',
  description_crm_people: 'De plek waar nieuwe relaties en leads kunnen worden toegevoegd.',
  description_form_forms: 'Stel een vragenlijst op voor jouw bezoekers met formulieren.',
  description_form_formsubmissions: 'De plek waar ingezonden formulieren van bezoekers worden geregistreerd.',
  description_form_notifications: 'Beheer wie e-mail notificaties moeten ontvangen bij het insturen van een formulier.',
  description_map_collections: 'Groepeer gecreëerde aanzichten tot een kaart.',
  description_map_views: 'Upload impressies, foto\'s of plattegronden van jouw vastgoed.',
  description_offer_collections: 'Configureer de presentatiemogelijkheden van jouw specifieke vastgoed aanbod.',
  description_pim_assetgroups: 'Groepeer objecten hiërarchisch door het aanmaken van categorieën.',
  description_pim_assets: 'Beheer en creëer alle objecten die jij wilt verhuren of verkopen.',
  description_pim_assettypes: 'Maak onderscheid tussen verschillende objecten middels zelf te benoemen assettypen.',
  description_settings_projects: 'Creëer, beheer of navigeer naar een project.',
  description_settings_users: 'Voeg nieuwe deelnemers toe aan jouw project.',
  description_website_websites: 'Maak websites op om jouw vastgoed te presenteren.',
  domains: 'domeinen',
  edit_artboard: 'tekengebied bewerken',
  edit_asset: 'asset bewerken',
  edit_assetgroup: 'assetgroep bewerken',
  edit_assettype: 'assettype bewerken',
  edit_collection: 'kaart bewerken',
  edit_form: 'formulier bewerken',
  edit_notification: 'melding bewerken',
  edit_person: 'persoon bewerken',
  edit_project: 'project bewerken',
  edit_user: 'gebruiker bewerken',
  edit_view: 'aanzicht bewerken',
  edit_website: 'website bewerken',
  edit_offer: 'aanbod bewerken',
  email_verified_sentence: 'je e-mailadres is bevestigd, voer een wachtwoord in dat je wil gebruiken om in te loggen bij Prasset',
  emailaddress: 'e-mailadres',
  end_date: 'eind datum',
  enter_a_new_password: 'voer een nieuw wachtwoord in',
  error: 'fout',
  export_data_as_csv: 'export data als csv',
  female: 'vrouw',
  formnotifications: 'meldingen', // same as notifications
  forms: 'formulieren',
  formsubmissions: 'inzendingen', // same as submissions
  go_to_crm: 'ga naar CRM',
  go_to_pim: 'ga naar alle',
  help_and_contact: 'hulp & contact',
  housing: 'huisvesting',
  i_forgot_my_password: 'ik ben mijn wachtwoord vergeten',
  interactive_maps: 'interactieve kaarten',
  invalid_credentials: 'incorrecte inloggegevens',
  item_deleted_successfully: 'item is verwijderd',
  item_not_found_question_type_to_search: 'item niet gevonden? type om te zoeken',
  items_deleted_successfully: 'items zijn verwijderd',
  light_logo: 'logo licht',
  login: 'inloggen',
  logout: 'uitloggen',
  male: 'man',
  name: 'naam',
  new_asset: 'nieuw object',
  new_assetgroup: 'nieuwe categorie',
  new_assettype: 'nieuwe assettype',
  new_collection: 'nieuwe kaart',
  new_component: 'nieuw component',
  new_form: 'nieuw formulier',
  new_notification: 'nieuwe melding',
  new_page: 'nieuwe pagina',
  new_password: 'nieuw wachtwoord',
  new_person: 'nieuw persoon',
  new_project: 'nieuw project',
  new_user: 'nieuwe gebruiker',
  new_website: 'nieuwe website',
  new_offer: 'nieuw aanbod',
  no_target_set: 'geen doel ingesteld',
  not_available_short: 'n.b.',
  notice: 'kennisgeving',
  notifications: 'meldingen',
  offer: 'aanbod',
  other_projects: 'overige projecten',
  page_number: 'pagina {number}',
  page_settings: 'pagina instellingen',
  pages: 'pagina\'s',
  password_set_successfully: 'wachtwoord succesvol ingesteld',
  password: 'wachtwoord',
  people: 'personen',
  person: 'persoon',
  pim: 'Objecten',
  presentation: 'presentatie',
  preview: 'voorvertoning',
  privacy: 'privacy',
  profile_and_settings: 'profiel & voorkeuren',
  profile: 'profiel',
  projects: 'projecten',
  recent_changed_assets: 'laatst bewerkte objecten',
  recover_password: 'wachtwoord herstellen',
  repeat_the_new_password: 'herhaal het nieuwe wachtwoord',
  request_for_additional_modules: 'extra modules aanvragen',
  reset_filters: 'filters resetten',
  reset_your_password: 'wachtwoord opnieuw instellen',
  residents: 'bewoners',
  save_and_back: 'opslaan & terug',
  save: 'opslaan',
  saved: 'opgeslagen',
  selected_project: 'geselecteerde project',
  set_password: 'wachtwoord instellen',
  setting_password_failed: 'wachtwoord instellen mislukt',
  settings: 'instellingen',
  something_went_wrong_while_fetching_data: 'er ging iets fout bij het ophalen van gegevens',
  start_date: 'start datum',
  submissions: 'inzendingen',
  success: 'succes',
  theme: 'thema',
  total_items_found: 'geen items gevonden | 1 item gevonden | {count} items gevonden',
  total_selected: '{count} geselecteerd',
  unknown_address: 'onbekend adres',
  users: 'gebruikers',
  verification_token_not_found: 'verificatie token niet gevonden',
  views: 'aanzichten',
  website_settings: 'website instellingen',
  website_visitors_last_week: 'website bezoekers afgelopen week',
  websites: 'website\'s',
  yes_delete: 'ja, verwijderen',
};
