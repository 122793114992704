<template>
  <div class="htmlfield relative bg-white">
    <VueEditor
      :name="name"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    VueEditor,
  },

  props: {
    placeholder: {
      type: String,
      default: null,
    },

    value: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      default: 'input',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
