import { DateTime } from 'luxon';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import useFieldConditions from '@/compositions/useFieldConditions';

/**
 * Interate async over array of promises
 */
export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

/**
 * Generate a uuid
 */
export function generateUUID() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => {
    return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
  });
}

/**
 * Uppercase first letter of a string
 */
export function ucfirst(str) {
  if (str && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return str;
  }
}

/**
 * Transform iso datetimeformat to locale format.
 */
export function formatDateTime(iso, locale = 'nl') {
  return DateTime.fromISO(iso)
    .setLocale(locale)
    .toLocaleString(DateTime.DATETIME_MED);
}

/**
 * Format price to locale format.
 */
export function formatPrice(value, currency='EUR', locale = 'nl-NL') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value);
}

/**
 * Sleep for x time in milliseconds.
 * async await version of setTimeout.
 */
export function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

/**
 * Map customfields from array with objects to associative object with values.
 */
export function mapCustomFields(custom_fields) {
  const fields = mapKeys(custom_fields, (value) => {
    return value.key;
  });

  return mapValues(fields, obj => {
    if (obj.value === 'null') return null;
    if (obj.value === 'true') return true;
    if (obj.value === 'false') return false;
    return obj.value;
  });
}

/**
 * Clear unavailable conditional values.
 *
 * @param fields object fieldset fields.
 * @param values object with values.
 * @return object Modified copy of passed values.
 */
export function clearUnavailableConditionalValues(fields, values) {
  const { fieldConditionsFor } = useFieldConditions();
  const data = { ...values };

  fields.map(field => {
    if (field.name && field.displayIf && !fieldConditionsFor('displayIf', data, field)) {
      data[field.name] = null;
    }
  });

  return data;
}
