<template>
  <div class="togglefield relative">
    <div class="flex items-center">
      <ToggleButton
          :value="value"
          :name="name"
          :height="30"
          :width="60"
          color="#048080"
          @input="$emit('input', $event)"
      />
      <div v-if="placeholder" class="ml-2">
        {{ placeholder }}
      </div>
    </div>
    <InputValidator :errors="error" class="text-input__error" />
  </div>
</template>


<script>
import InputValidator from '@/components/alerts/InputValidator';
import ToggleButton from 'vue-js-toggle-button/src/Button';

export default {
  components: {
    InputValidator,
    ToggleButton,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: 'input',
    },

    error: {
      value: [Array, Object],
      default: () => {
        return null;
      },
    },
  },
};
</script>
