<template>
  <div class="markdownfield">
    <div ref="editor" class="h-32 bg-white" v-html="htmlValue"></div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import { onMounted } from '@vue/composition-api';
import Quill from 'quill';
import MarkdownIt from 'markdown-it';
import Turndown from 'turndown';
// import marked from 'marked';

export default {
  props: {
    name: {
      type: String,
      default: 'input',
    },

    value: {
      type: String,
      default: '',
    },
  },

  setup(props, { refs, emit }) {
    const turndown = new Turndown({
      codeBlockStyle: 'fenced',
      headingStyle: 'atx',
    });

    const markdownIt = new MarkdownIt({
      html: true,
    });

    const htmlValue = markdownIt.render(props.value);

    onMounted(() => {
      const editor = new Quill(refs.editor, {
        theme: 'snow',
        modules: {
          toolbar: true,
        },
      });

      editor.on('text-change', () => {
        const html = editor.container.firstChild.innerHTML;
        const markdownDocument = turndown.turndown(html);
        emit('input', markdownDocument);
      });
    });

    return {
      htmlValue,
    };
  },
};
</script>
