import Api from '@/providers/api';

/**
 * Change password of active user.
 */
async function changePassword(oldPassword, newPassword) {
  const response = await Api().post(`profile/change-password`, {
    old_password: oldPassword,
    new_password: newPassword,
  });

  return response.data.data;
}

export default function useProfile() {
  return {
    changePassword,
  };
}
