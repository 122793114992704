<template>
  <div v-if="errors && errors.length > 0" class="inputvalidator">
    <button class="text-prasset-red-500 bg-white rounded-full w-5 h-5 focus:bg-gray-200 focus:outline-none"><i class="icon-info" /></button>
    <div v-if="errors && errors.length > 0" class="inputvalidator__flyout hidden absolute border border-gray-400 bg-white shadow-lg p-2 right-0 mt-1 -mr-1 rounded-sm">
      <p v-for="error in errors" :key="error" class="whitespace-no-wrap text-prasset-gray-800 text-sm">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => {
        return false;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .inputvalidator {
    button:hover ~ &__flyout,
    button:focus ~ &__flyout {
      display: block;
    }

    &__flyout {
      &:before {
        content: '';
        @apply .block
               .w-0
               .h-0
               .absolute
               .bottom-full
               .right-0
               .mr-2
               .border-transparent
               .border-6;

        border-bottom-color: theme('colors.white');
        filter: drop-shadow(0px 0px 0px theme('colors.gray.900'));
      }
    }
  }
</style>
