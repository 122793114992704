import Login from '@/modules/auth/views/templates/Login';
import useAuth from "@/compositions/useAuth";
import { redirectToUserRelevantPage } from "@/providers/router";

export default [
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: async (to, from , next) => {
      const { loginByAccessToken } = useAuth();

      if (to.query.access) {
        const access = atob(to.query.access);
        const response = await loginByAccessToken(access);

        redirectToUserRelevantPage(response, route => {

          next(route);
        });
      }

      next();
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      import(
        /* webpackChunkName: "Logout" */ "@/modules/auth/views/templates/Logout"
      )
  },
  {
    path: "/password/recover",
    name: "password-recover",
    component: () =>
      import(
        /* webpackChunkName: "Recover" */ "@/modules/auth/views/templates/password/Recover"
      )
  },
  {
    path: "/password/reset",
    name: "password-reset",
    component: () =>
      import(
        /* webpackChunkName: "Reset" */ "@/modules/auth/views/templates/password/Reset"
      )
  }
];
