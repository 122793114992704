<template>
  <AuthLayout>
    <form class="grid grid-cols-1 gap-6" @submit.prevent="submit()">
      <div class="col-span-1">
        <label for="email" class="text-sm text-prasset-gray-800 mb-2 block">{{ $tuf('emailaddress') }}</label>
        <TextField id="email" name="email" :placeholder="$t('emailaddress')" v-model="form.email" />
      </div>
      <div class="col-span-1">
        <label for="password" class="text-sm text-prasset-gray-800 mb-2 block">{{ $tuf('password') }}</label>
        <PasswordField id="password" name="password" :placeholder="$t('password')" v-model="form.password" />
      </div>
      <div class="col-span-1 flex flex-col items-center">
        <button
          class="button button--opague w-full max-w-xs relative"
          :class="{ 'opacity-25': loading }"
          type="submit"
          :disabled="loading">
          <span :class="{ 'invisible': loading }">
            {{ $tuf('login') }}
          </span>
          <span v-if="loading" class="absolute inset-0 flex justify-center items-center">
            <LoadingIndicator />
          </span>
        </button>
        <router-link :to="{name: 'password-recover', query: { email: form.email } }" class="text-xs text-prasset-gray-800 mt-6">
          {{ $tuf('i_forgot_my_password') }}
        </router-link>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import useAuth from '@/compositions/useAuth';
import { redirectToUserRelevantPage } from '@/providers/router';
import { toRefs, reactive } from '@vue/composition-api';
import AuthLayout from '@/modules/auth/views/layouts/auth';
import LoadingIndicator from '@/components/LoadingIndicator';
import PasswordField from '@/components/field/Password';
import TextField from '@/components/field/Text';

export default {
  name: 'Login',

  components: {
    AuthLayout,
    LoadingIndicator,
    PasswordField,
    TextField,
  },

  setup(props, { root }) {
    const { login } = useAuth();

    const state = reactive({
      form: {
        email: '',
        password: '',
      },
      loading: false,
    });

    async function submit() {
      state.loading = true;

      try {
        const response = await login(state.form.email, state.form.password);

        redirectToUserRelevantPage(response.me, route => {
          root.$router.replace(route);
        });

        state.loading = false;
      } catch {
        state.loading = false;

        root.$notify({
          type: 'error',
          title: root.$tuf('error'),
          text: root.$tuf('invalid_credentials'),
        });
      }
    }

    return {
      ...toRefs(state),
      submit,
    };
  },
};
</script>
