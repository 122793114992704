export default [
  {
    projectScope: true,
    name: 'offer',
    path: 'offer',
    meta: {
      title: 'Aanbod'
    },
    component: () =>
      import(
        /* webpackChunkName: "Index" */ '@/modules/offer/views/templates/Index'
      ),

    children: [
      {
        projectScope: true,
        name: 'offer-create',
        path: 'create',
        meta: {
          title: 'Aanbod aanmaken'
        },
        component: () =>
          import(
            /* webpackChunkName: "Create" */ '@/modules/offer/views/templates/Create'
          ),
        sidebar: true
      },
      {
        projectScope: true,
        name: 'offer-view',
        path: ':entry',
        meta: {
          title: 'Aanbod bekijken'
        },
        component: () =>
          import(
            /* webpackChunkName: "View" */ '@/modules/offer/views/templates/View'
          ),
        sidebar: true
      },
      {
        projectScope: true,
        name: 'offer-edit',
        path: ':entry/edit',
        meta: {
          title: 'Aanbod bewerken'
        },
        component: () =>
          import(
            /* webpackChunkName: "Edit" */ '@/modules/offer/views/templates/Edit'
          ),
        sidebar: true
      }
    ]
  }
];
