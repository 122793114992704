import Vue from 'vue';
import { ucfirst } from '@/providers/helpers';

// $t with uppercase first
Vue.prototype.$tuf = function(...args) {
  return ucfirst(this.$t(...args));
};

// $t with uppercase all
Vue.prototype.$tu = function(...args) {
  return this.$t(...args).toUpperCase();
};

// $t with lowercase all
Vue.prototype.$tl = function(...args) {
  return this.$t(...args).toLowerCase();
};

// $tc with uppercase first
Vue.prototype.$tcuf = function(...args) {
  return ucfirst(this.$tc(...args));
};

// $tc with uppercase first
Vue.prototype.$tcu = function(...args) {
  return this.$tc(...args).toUpperCase();
};

// $tc with lowercase all
Vue.prototype.$tcl = function(...args) {
  return this.$tc(...args).toLowerCase();
};
