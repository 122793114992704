import { toRefs, reactive } from '@vue/composition-api';
import Api, { setAccessToken as setBearerAccessToken } from '@/providers/api';

const state = reactive({
  accessToken: window.localStorage.accessToken,
  me: null,
  accessTokenObserver: null,
  accessTokenRefreshCount: null,
  loading: true,
});

setBearerAccessToken(window.localStorage.accessToken);

function startAccessTokenObserver() {
  stopAccessTokenObserver(); // fixes hot reload issues.

  state.accessTokenObserver = setInterval(async () => {
    // TODO: const expiresIn = parseInt(window.localStorage.expires_in || 3600); // ttl in seconds
    const expiresOn = parseInt(window.localStorage.expires_on || 0);
    const now = new Date().getTime();
    const diffInMinutes = (((expiresOn - now) % 86400000) % 3600000) / 60000;
    if (now >= expiresOn || diffInMinutes <= 30) { // FIXME: use of expiresIn
      state.accessTokenRefreshCount += 1; // TODO: implement max times refresh.
      refresh();
    }
  }, 60000);
}

function stopAccessTokenObserver() {
  clearInterval(state.accessTokenObserver);
  state.accessTokenObserver = null;
}

function setAccessTokenExpiry(expires_in) {
  window.localStorage.expires_in = expires_in;
  window.localStorage.expires_on = new Date(
    new Date().getTime() +
    expires_in * 60000 // minutes to milliseconds
  ).getTime();
}

async function validateAuthentication() {
  if (!state.accessToken) {
    stopAccessTokenObserver();
    throw new Error('unauthorized');
  } else {
    try {
      const response = await Api().post('auth/me');
      state.me = response.data.data;
      startAccessTokenObserver();
      return response.data.data;
    } catch {
      logout();
      throw new Error('unauthorized');
    }
  }
}

async function login(email, password) {
  try {
    const response = await Api().post('auth/login', { email, password });
    state.accessToken = response.data.data.access_token;
    state.me = response.data.data.me;

    setBearerAccessToken(state.accessToken);
    setAccessTokenExpiry(response.data.data.expires_in);
    startAccessTokenObserver();
    return response.data.data;
  } catch (response) {
    return response;
  }
}

async function loginByAccessToken(obj) {
  obj = JSON.parse(obj);
  state.accessToken = obj.access_token;

  setBearerAccessToken(obj.access_token);
  setAccessTokenExpiry(obj.expires_in);
  startAccessTokenObserver();

  return await fetchMe();
}

async function fetchMe() {
  return await Api().post('auth/me')
    .then(({ data }) => {
      state.me = data.data;
      state.loading = false;
      return data.data;
    })
    .catch((error) =>  error);
}

function logout() {
  state.accessToken = null;
  state.me = null;
  setBearerAccessToken(null);
  stopAccessTokenObserver();
}

async function refresh() {
  try {
    const response = await Api().post('auth/refresh');
    state.accessToken = response.data.data.access_token;
    setBearerAccessToken(response.data.data.access_token);
    setAccessTokenExpiry(response.data.data.expires_in);
  } catch {
    console.error('Unable to refresh accesstoken.');
  }
}

function changeProject(project) {
  state.me.recent_project = project;
}

async function verify(token, password) {
  return await Api().post('auth/verify-email', { token, password });
}

export default function useAuth() {
  return {
    ...toRefs(state),
    fetchMe,
    login,
    loginByAccessToken,
    logout,
    changeProject,
    validateAuthentication,
    verify,
  };
}
