<template>
    <label class="checkfield relative block min-h-4 min-w-4" :class="optionClasses">
        <input class="appearance-none sr-only"
               type="checkbox"
               ref="input"
               :name="name"
               :id="id"
               :disabled="disabled"
               :value="value"
               :checked="isChecked"
               @change="toggle"
        />
        <span class="checkfield__box bg-white border-2 border-prasset-gray-800 flex absolute justify-center items-center w-4 h-4"></span>
        <span v-if="$slots.default" class="checkfield__label block leading-none box-content pl-6 select-none"><slot /></span>
    </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    name: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    checked: {
      type: Boolean,
      default: false,
    },

    value: {
      default: true,
    },

    modelValue: {
      default: undefined,
    },
  },

  computed: {
    optionClasses() {
      return {
        'checkfield--disabled': this.disabled,
      };
    },

    isChecked() {
      if (this.modelValue === undefined) {
        return this.checked;
      }

      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) > -1;
      }

      return !!this.modelValue;
    },
  },

  mounted() {
    if (this.checked && !this.isChecked) {
      this.toggle();
    }
  },

  watch: {
    checked(newValue) {
      if (newValue !== this.isChecked) {
        this.toggle();
      }
    },
  },

  methods: {
    toggle() {
      const valueType = typeof(this.value);

      let value;
      if (Array.isArray(this.modelValue)) {
        value = this.modelValue.slice(0);
        if (this.isChecked) {
          value.splice(value.indexOf(this.value), 1);
        } else {
          value.push(this.value);
        }
      } else if (valueType === 'boolean') {
        value = !this.isChecked;
      } else {
        if (this.isChecked) {
          value = null;
        } else {
          value = this.value;
        }
      }

      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss">
.checkfield {
    &__box:before {
        content: "\e905";

        @apply .font-icomoon
               .text-xs
               .text-white
               .leading-none
               .opacity-0
               .transition-opacity
               .duration-300;
    }

    &:hover &__box,
    input:focus ~ &__box {
        @apply .border-prasset-green-500;
    }

    input:checked ~ &__box {
        @apply .bg-prasset-green-500
               .border-prasset-green-500
               .opacity-100;

        &:before {
            @apply .opacity-100;
        }
    }
}
</style>
