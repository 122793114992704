<template>
  <div class="linkfield relative bg-white placeholder-gray-600 font-base font-normal rounded border border-gray-300 py-2 px-4 w-full focus-within:border-prasset-green-500"
       :class="{ 'text-input--error': error && error.length > 0 }"
  >

    <div class="flex border-b border-gray-300 -mx-4 -mt-2 mb-2">
      <button
        type="button"
        @click.prevent="tab = 'url'"
        class="text-xs py-2 ml-4 -mb-px border-b border-transparent focus:outline-none"
        :class="{ 'text-prasset-green-500 border-prasset-green-500': tab === 'url', 'text-prasset-gray-400 focus:text-prasset-gray-900': tab !== 'url'}"
      >
        Link
      </button>
      <button
        type="button"
        @click.prevent="tab = 'label'"
        class="text-xs py-1 ml-4 -mb-px border-b border-transparent focus:outline-none"
        :class="{ 'text-prasset-green-500 border-prasset-green-500': tab === 'label', 'text-prasset-gray-400 focus:text-prasset-gray-900': tab !== 'label'}"
      >
        Label
      </button>
      <button
        type="button"
        @click.prevent="tab = 'target'"
        class="text-xs py-1 ml-4 -mb-px border-b border-transparent focus:outline-none"
        :class="{ 'text-prasset-green-500 border-prasset-green-500': tab === 'target', 'text-prasset-gray-400 focus:text-prasset-gray-900': tab !== 'target'}"
      >
        Bestemming
      </button>
      <button
        type="button"
        @click.prevent="tab = 'styling'"
        class="text-xs py-1 ml-4 -mb-px border-b border-transparent focus:outline-none"
        :class="{ 'text-prasset-green-500 border-prasset-green-500': tab === 'styling', 'text-prasset-gray-400 focus:text-prasset-gray-900': tab !== 'styling'}"
      >
        Weergave
      </button>
    </div>

      <div v-if="tab === 'url'">
        <div class="flex flex-row pt-2 pb-3 mb-2 border-b">
          <label class="radiofield relative block min-h-4 min-w-4">
            <input class="appearance-none sr-only"
              type="radio"
              value="internal"
              :checked="form.type === 'internal'"
              @click="form.type  = 'internal'"
            />
            <span class="radiofield__box rounded-full bg-white border-2 border-prasset-gray-800 flex absolute justify-center items-center w-4 h-4" />
            <span class="radiofield__label block leading-none text-prasset-gray-400 text-sm pl-6 select-none">Interne link</span>
          </label>

          <label class="radiofield relative block min-h-4 min-w-4 ml-4">
            <input class="appearance-none sr-only"
              type="radio"
              value="internal"
              :checked="form.type  === 'external'"
              @click="form.type = 'external'"
            />
            <span class="radiofield__box rounded-full bg-white border-2 border-prasset-gray-800 flex absolute justify-center items-center w-4 h-4" />
            <span class="radiofield__label block leading-none text-prasset-gray-400 text-sm pl-6 select-none">Externe link</span>
          </label>
        </div>
        <input v-if="form.type === 'external'" type="text" v-model="form.href" placeholder="https://" class="w-full focus:outline-none" />
        <select v-if="form.type === 'internal'" v-model="form.page_id" class="w-full focus:outline-none">
          <option>-</option>
          <option v-for="link in intern_links" :key="link.id" :value="link.id">
            {{ link.name }}
          </option>
        </select>
      </div>


    <input v-if="tab === 'label'" type="text" v-model="form.label" placeholder="Label" class="w-full focus:outline-none" />

    <select v-if="tab === 'target'" v-model="form.target" class="w-full focus:outline-none">
      <option value="_self">Zelfde venster</option>
      <option value="_blank">Nieuw venster</option>
    </select>

    <select v-if="tab === 'styling'" v-model="form.style" class="w-full focus:outline-none">
      <option value="primary">Primair</option>
      <option value="light">Secundair</option>
      <option value="outlined">Tertiair</option>
    </select>

    <InputValidator :errors="error" class="text-input__error" />
  </div>
</template>

<script>
import { toRefs, reactive, watch, computed } from '@vue/composition-api';
import InputValidator from '@/components/alerts/InputValidator';
import useWebsite from '@/compositions/useWebsite';


export default {
  components: {
    InputValidator,
  },

  props: {
    name: {
      type: String,
      default: 'input',
    },

    value: {
      type: Object,
      default: () => {},
    },

    error: {
      value: [Array, Object],
      default: () => {
        return null;
      },
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      tab: 'url',
      form: {
        type: 'internal',
        href: '',
        page_id: '',
        target: '_self',
        label: '',
        style: 'primary',
      },
    });


    const { website } = useWebsite();


    const intern_links = computed(() => {
      const links = [];

      if (!website.value) {
        return [];
      }

      website.value.pages
        .forEach(page => {
          links.push({
            name: page.name,
            id: page.id,
          });
        });

      return links;
    });

    watch(() => state.form, value => {
      if (
        (value.href === '' && value.type === 'external') ||
        (value.id === '' && value.type === 'internal')
      ) {
        emit('input', null);
      } else {
        emit('input', value);
      }
    }, { deep: true });

    if (props.value) {
      state.form.href = props.value.href;
      state.form.target = props.value.target;
      state.form.label = props.value.label;
      state.form.page_id = props.value.page_id;
      state.form.style = props.value.style;
      state.form.type = props.value.type;
    }

    return {
      intern_links,
      website,
      ...toRefs(state),
    };
  },
};
</script>
