<template>
  <div class="textfield relative">
    <input
      class="bg-white placeholder-gray-600 font-base font-normal rounded border border-gray-300 py-2 px-4 w-full focus:outline-none focus:border-prasset-green-500"
      :type="type"
      :class="optionClasses"
      :placeholder="placeholder"
      :required="required"
      :name="name"
      :value="value"
      :id="id"
      :readonly="readonly"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
    />

    <InputValidator :errors="error" class="text-input__error" />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import InputValidator from '@/components/alerts/InputValidator';

export default {
  components: {
    InputValidator,
  },

  props: {
    type: {
      type: String,
      default: 'text',
    },

    placeholder: {
      type: String,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number],
      default: '',
    },

    name: {
      type: String,
      default: 'input',
    },

    id: {
      type: String,
      default: null,
    },

    error: {
      value: [Array, Object],
      default: () => {
        return null;
      },
    },
  },

  setup(props) {
    const optionClasses = computed(() => {
      return {
        'textfield--disabled': props.disabled,
        'textfield--readonly': props.readonly,
        'text-input--error': props.error && props.error.length > 0,
      };
    });

    return {
      optionClasses,
    };
  },
};
</script>

<style lang="scss">
.textfield {
  &--readonly {
      @apply .bg-gray-100;

      &:after {
          @apply .bg-gray-100;
      }
  }

  &--disabled {
      @apply .bg-gray-100
             .text-gray-300;

      &:after {
          @apply .bg-gray-100;
      }
  }
}
</style>
