<template>
  <ConfirmDialog v-if="isOpen"
    :title="title"
    :isDismissable="isDismissable"
    :confirmButton="confirmButton"
    :cancelButton="cancelButton"
    :onConfirm="onConfirm"
    @close="handleClose"
  >
    <div v-if="main && typeof(main) === 'string'" slot="default" v-html="main"></div>
    <Component v-if="main && typeof(main) === 'function'" :is="main" v-bind="props" />
    <Component v-if="footer" slot="footer" :is="footer" v-bind="props" @close="handleClose" />
  </ConfirmDialog>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog';
import EventBus from '@/eventbus';

export default {
  components: {
    ConfirmDialog,
  },

  data () {
    return {
      name: null,
      isOpen: false,
      isDismissable: true,
      confirmButton: null,
      cancelButton: null,
      onConfirm: null,

      main: null,
      footer: null,

      title: '',
      props: null,
    };
  },

  created () {
    EventBus.$on('confirm:open', ({
      main,
      name = null,
      footer = null,
      title = '',
      props = null,
      isDismissable = true,
      confirmButton,
      cancelButton,
      onConfirm,
    }) => {
      this.name = name;
      this.main = main;
      this.footer = footer;
      this.title = title;
      this.props = props;
      this.isDismissable = isDismissable;
      this.confirmButton = confirmButton;
      this.cancelButton = cancelButton;
      this.onConfirm = onConfirm;
      this.isOpen = true;
    });

    EventBus.$on('confirm:close', ({ name = null }) => {
      if (this.name === name) {
        this.handleClose();
      }
    });
  },

  methods: {
    handleClose () {
      this.main = null;
      this.footer = null;
      this.title = null;
      this.props = null;
      this.isOpen = false;
    },
  },
};
</script>
