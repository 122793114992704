<template>
    <div class="bg-white projects-menu pl-4 pt-8" :class="{ active : projectsOpen }">
      <button type="button" class="button button--clear button--small button--clamp" @click="projectsOpen = !projectsOpen">
        <i class="ri-arrow-left-line text-xl"></i>
      </button>

      <div class="ml-12 mr-8 mt-8">
        <h2 class="text-2xl font-medium mb-8">{{ $tuf('projects') }}</h2>

        <div class="flex justify-between w-full">
          <div class="rounded w-10 h-10 overflow-hidden mr-3 flex-none bg-gray-300">
            <img v-if="getImpression(project)" :src="getImpression(project)" class="object-fit w-full h-full"/>
          </div>
          <div class="truncate">
            {{ project.name }}
            <div class="text-xs text-gray-500 leading-none">{{ $tuf('selected_project') }}</div>
          </div>
          <div>
            <button type="button" class="button button--clear button--small button--clamp ml-1" @click="editProject()" :title="$tuf('edit_project')">
              <i class="ri-settings-4-line"></i>
            </button>
          </div>
        </div>

        <div class="grid grid-cols-1 gap-3 mt-12">
          <p class="font-medium text-gray-800 border-b border-gray-300 pb-2 mb-2">{{ $tuf('other_projects') }}</p>

          <button
            type="button"
            @click="navigateToProject(otherProject)"
            class="flex flex-row items-center text-prasset-gray-600 focus:outline-none focus:text-prasset-gray-900 hover:text-prasset-gray-900"
            v-for="(otherProject, idx) in otherProjects"
            :key="idx"
          >
            <div class="rounded w-6 h-6 overflow-hidden mr-3 flex-none bg-gray-300">
              <img v-if="getImpression(otherProject)" :src="getImpression(otherProject)" class="object-fit w-full h-full"/>
            </div>
            <div class="text-base truncate">{{ otherProject.name }}</div>
          </button>

          <button class="flex flex-row items-center text-prasset-green-600 rounded mt-4" @click="newProject()">
            <span class="w-6 h-6 flex items-center justify-center">
              <i class="ri-add-circle-line text-xl leading-none"></i>
            </span>
            <span class="ml-3 text-base font-medium">{{ $tuf('add_project') }}</span>
          </button>
        </div>
      </div>
    </div>
</template>


<script>
import find from 'lodash/find';
import get from 'lodash/get';
import { projectsOpen } from '@/compositions/useNavigation';
import { project, otherProjects } from '@/compositions/useProjects';

export default {
  name: 'ProjectsMenu',

  setup(props, { root }) {

    function newProject() {
      projectsOpen.value = false;
      root.$router.push({ name: 'project-create' });
    }

    function editProject() {
      projectsOpen.value = false;
      root.$router.push({
        name: 'settings-edit',
        params: {
          project_id: project.value.id,
          entry: project.value.id,
          type: 'projects',
        },
      });
    }

    function navigateToProject(project) {
      projectsOpen.value = false;
      root.$router.push({ name: 'dashboard', params: { project_id: project.id } });
    }

    function getImpression(p) {
      const mainImage = find(p.media, { collection: 'main_image' });
      return get(mainImage, 'urls.small');
    }

    return {
      newProject,
      editProject,
      navigateToProject,
      projectsOpen,
      project,
      otherProjects,
      getImpression,
    };
  },
};
</script>


<style lang="scss">
  .projects-menu {
    transition: transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    width: 20rem;
    position: absolute;
    height: 100%;
    left: 0;
    z-index: 20;
    transform: translate3d(-100%,0,0);

    &.active {
      transform: translate3d(0,0,0);

      &:after {
        opacity: 1
      }
    }

    &:after {
      transition: opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
      opacity: 0;
      pointer-events: none;
      content: '';
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0,0,0,.2);
      z-index: 10;
      transform: translate3d(20rem,0,0);
    }
  }
</style>
