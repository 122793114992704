<template>
  <div class="relative me-menu">
    <button @click.stop="$refs.flyout.toggleFlyout" class="focus:outline-none" :class="{ active: isOpen }">
      <span class="block w-8 h-8 overflow-hidden rounded-full bg-prasset-gray-800">
        <img class="w-full" :src="`https://gravatar.com/avatar/${me.email_md5}?s=30&d=mp`" />
      </span>
    </button>

    <Flyout ref="flyout" :class="`me-flyout me-flyout--${flyoutPosition}`">
      <router-link @click.native="$refs.flyout.closeFlyout" :to="{ name: 'profile' }" class="me-flyout__header block focus:text-prasset-green-400 hover:text-prasset-green-400 focus:outline-none">
        <span class="me-flyout__header__user block">{{ me.name }} van {{ me.customer.name }} </span>
        <span class="me-flyout__header__email block">{{ me.email }}</span>
      </router-link>
      <div class="me-flyout__menu">
        <slot v-if="showMenu">
          <div class="px-3">
            <button
              v-for="locale in locales"
              :key="locale.locale"
              @click="setLocale(locale.locale)"
              class="button button--small button--clear uppercase text-xs mr-1"
              :class="{ 'button--outlined': isActiveLocale(locale) }"
            >{{ locale.language }}</button>
          </div>

          <div class="separator"></div>
          <a href="#" @click.prevent="showChangePasswordModal">{{ $tuf('change_password') }}</a>
          <!-- <router-link @click.native="$refs.flyout.closeFlyout" :to="{ name: 'request-module' }">{{ $tuf('request_for_additional_modules') }}</router-link>
          <router-link :to="{ name: 'support' }">{{ $tuf('help_and_contact') }}</router-link>
          <router-link :to="{ name: 'privacy' }">{{ $tuf('privacy') }}</router-link> -->
        </slot>
        <!-- <div class="separator"></div> -->
        <router-link :to="{ name: 'logout' }">{{ $tuf('logout') }}</router-link>
      </div>
    </Flyout>

    <ChangePasswordModal ref="change_password_modal" />
  </div>
</template>

<script>
import useAuth from '@/compositions/useAuth';
import useLocale from '@/compositions/useLocale';
import { ref, computed } from '@vue/composition-api';
import Flyout from '@/components/Flyout';
import ChangePasswordModal from '@/modules/core/views/components/ChangePasswordModal';

export default {
  components: {
    Flyout,
    ChangePasswordModal,
  },

  props: {
    showMenu: {
      type: Boolean,
      default: true,
    },

    flyoutPosition: {
      type: String,
      default: 'right-up',
    },
  },

  setup(props, { refs }) {
    const { me } = useAuth();
    const { locales, isActiveLocale, setLocale } = useLocale();
    const flyout = ref(null); // magically binds to node.

    const isOpen = computed(() => {
      return flyout.value ? flyout.value.isOpen : false;
    });

    function showChangePasswordModal() {
      refs.flyout.closeFlyout();
      refs.change_password_modal.show();
    }

    return {
      flyout,
      isOpen,
      me,
      showChangePasswordModal,
      locales,
      isActiveLocale,
      setLocale,
    };
  },
};
</script>

<style lang="scss">
.me-menu {
  .me-flyout {
    position: absolute;
    color: #000;
    background: white;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin: 10px 10px 0 10px;
    min-width: 250px;

    &:before {
      content: '';
      display: block;
      border: 5px solid transparent;
      width: 0;
      height: 0;
      position: absolute;
      filter: drop-shadow(0px 0px 0px #777);
    }

    &--right-up {
      bottom: 0.5rem;
      left: 100%;

      &:before {
        border-right-color: white;
        bottom: 10px;
        right: 100%;
      }
    }

    &--left-down {
      top: 0;
      right: 100%;

      &:before {
        border-left-color: white;
        top: 10px;
        left: 100%;
      }
    }

    &__header {
      padding: 20px 20px 10px 20px;
      &__user {
        font-size: 16px;
        font-weight: 500;
      }
      &__email {
        color: #777;
        font-size: 13px;
      }
    }

    &__menu {
      list-style: none;
      padding: 0;
      margin: 0;
      a {
        display: flex;
        margin: 10px 0;
        padding: 2px 20px;
        white-space: nowrap;
        color: #333;
        &:hover, &:focus {
          color: #048080;
        }
      }
      .separator {
        border-top: 1px solid #ddd;
        margin: 10px 0;
      }
    }
  }
}
</style>
