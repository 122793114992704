<template>
    <label class="radiofield relative block min-h-4 min-w-4" :class="optionClasses">
        <input class="appearance-none sr-only"
               type="radio"
               ref="input"
               :name="name"
               :id="id"
               :disabled="disabled"
               :value="value"
               :checked="isSelected"
               @change="select"
        />
        <span class="radiofield__box rounded-full bg-white border-2 border-prasset-gray-800 flex absolute justify-center items-center w-4 h-4"></span>
        <span v-if="$slots.default" class="radiofield__label block leading-none box-content pl-6 select-none"><slot /></span>
    </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    name: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    checked: {
      type: Boolean,
      default: false,
    },

    value: {
      default: '1',
    },

    modelValue: {
      default: undefined,
    },
  },

  computed: {
    optionClasses() {
      return {
        'radiofield--disabled': this.disabled,
      };
    },

    isSelected() {
      if (this.modelValue === undefined) {
        return this.checked;
      }

      if (this.modelValue == this.value) {
        return true;
      }

      return false;
    },
  },

  methods: {
    select() {
      const valueType = typeof(this.value);

      let value;
      if (valueType === 'boolean') {
        value = !this.isSelected;
      } else {
        value = this.value;
      }

      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss">
.radiofield {
    &__box:before {
        content: '';

        @apply .opacity-0
               .transition-opacity
               .duration-300
               .bg-prasset-green-500
               .h-2
               .w-2
               .rounded-full;
    }

    &:hover &__box,
    input:focus ~ &__box {
        @apply .border-prasset-green-500;
    }

    input:checked ~ &__box {
        @apply .bg-white
               .border-prasset-green-500
               .opacity-100;

        &:before {
            @apply .opacity-100;
        }
    }
}
</style>
