<template>
  <button class="flex items-center text-xs px-4 focus:outline-none focus:bg-gray-200 w-64" :class="{ 'bg-prasset-gray-100': source.checked }" @click="onChange">
    <i class="text-xl mr-4" :class="source.id" />
    {{ source.text }}
  </button>
</template>

<script>
export default {
  name: 'IconPickerItem',

  props: {
    source: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    onChange() {
      this.dispatch('IconPicker', 'clickItem', this.source.id);
    },

    dispatch(componentName, eventName, ...rest) {
      let parent = this.$parent || this.$root;
      let name = parent.$options.name;

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;
        if (parent) {
          name = parent.$options.name;
        }
      }

      if (parent && parent[eventName]) {
        parent[eventName].call(parent, ...rest);
      }
    },
  },
};
</script>
