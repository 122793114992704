export default [
  {
    projectScope: true,
    name: 'website',
    path: 'websites',
    component: () => import('@/modules/website/views/templates/Websites' /* webpackChunkName: "website.websites" */),
    children: [
      {
        projectScope: true,
        name: 'website-create',
        path: 'create',
        component: () => import('@/modules/website/views/templates/WebsiteCreate' /* webpackChunkName: "website.website.create" */),
      },
    ],
  },
  {
    projectScope: true,
    name: 'website-page',
    path: 'websites/:website_id/:page_id',
    component: () => import('@/modules/website/views/templates/Pages' /* webpackChunkName: "website.pages" */),

    children: [
      {
        projectScope: true,
        name: 'website-edit',
        path: 'settings',
        component: () => import('@/modules/website/views/templates/WebsiteEdit' /* webpackChunkName: "website.website.edit" */),
      },
      {
        projectScope: true,
        name: 'website-page-create',
        path: 'create',
        component: () => import('@/modules/website/views/templates/PageCreate' /* webpackChunkName: "website.page.create" */),
      },
      {
        projectScope: true,
        name: 'website-page-edit',
        path: 'edit',
        component: () => import('@/modules/website/views/templates/PageEdit' /* webpackChunkName: "website.page.edit" */),
      },
      {
        projectScope: true,
        name: 'website-component-create',
        path: 'components/create',
        component: () => import('@/modules/website/views/templates/ComponentCreate' /* webpackChunkName: "website.component.create" */),
      },
      {
        projectScope: true,
        name: 'website-component-edit',
        path: ':type/:component_id',
        props: route => Object.assign({}, route.params),
        component: () => import('@/modules/website/views/templates/ComponentEdit' /* webpackChunkName: "website.component.edit" */),
      },
      {
        projectScope: true,
        name: 'website-domain-edit',
        path: 'domains',
        component: () => import('@/modules/website/views/templates/DomainEdit' /* webpackChunkName: "website.domain.edit" */),
      },
    ],
  },
];
