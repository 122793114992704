import { toRefs, reactive } from '@vue/composition-api';
import Api from '@/providers/api';
import find from 'lodash/find';

const state = reactive({
  websites: [],
  website: null,
  page: null,
  componentTypes: {},
});

/**
 * Remove all data that is not from the given project.
 */
function removeReferences(project_id) {
  state.websites = state.websites.filter(website => website.project_id === project_id);
}

/**
 * Fetch available themes from api.
 */
async function fetchThemes(project_id) {
  const response = await Api().get(`projects/${project_id}/websites/themes`);
  return response.data.data.themes;
}

/**
 * Fetch project websites from api.
 */
async function fetchWebsites(project_id) {
  const response = await Api().get(`projects/${project_id}/websites`);
  return response.data.data.map(website => {
    return { ...website, project_id };
  });
}

/**
 * Fetch project website from api.
 */
async function fetchWebsite(project_id, website_id) {
  const response = await Api().get(`projects/${project_id}/websites/${website_id}`);
  return { ...response.data.data, project_id };
}

/**
 * Fetches and reload the website in state (memory).
 */
async function reloadWebsite(project_id, website_id) {
  const data = await fetchWebsite(project_id, website_id);
  state.website = data;
  state.websites = state.websites.map(website => (website.id === website_id ? data : website));
}

/**
 * Load a website in state. Uses cache.
 */
async function loadWebsite(project_id, website_id) {
  const found = find(state.websites, { project_id, id: website_id });
  if (found) {
    state.website = found;
  } else {
    state.website = await fetchWebsite(project_id, website_id);
  }
}

/**
 * Load websites in state.
 */
async function loadWebsites(project_id) {
  removeReferences(project_id);
  state.websites = await fetchWebsites(project_id);
}

/**
 * Load componentTypes in state.
 */
async function loadComponentTypes(project_id) {
  if (Object.keys(state.componentTypes).length === 0) {
    state.componentTypes = await fetchComponentTypes(project_id);
  }
}

/**
 * Insert a website object into the websites state.
 */
function insertWebsite(project_id, data) {
  const found = find(state.websites, { project_id, id: data.id });
  if (!found) {
    state.websites.push({ ...data, project_id });
  }
}

/**
 * Create a website.
 */
async function createWebsite(project_id, data) {
  const response = await Api().post(`projects/${project_id}/websites`, data);
  return { ...response.data.data, project_id };
}

/**
 * Update a website.
 */
async function updateWebsite(project_id, website_id, data) {
  const response = await Api().patch(`projects/${project_id}/websites/${website_id}`, data);
  return { ...response.data.data, project_id };
}

/**
 * Delete a website.
 */
async function deleteWebsite(project_id, website_id) {
  await Api().delete(`projects/${project_id}/websites/${website_id}`);
}

/**
 * Unloads a website from memory.
 */
function unloadWebsite(website_id) {
  const index = state.websites.findIndex(
    website => website.id === website_id
  );
  state.websites.splice(index, 1);
}

/**
 * Unloads a page from memory.
 */
function unloadPage(page_id) {
  const pageIdx = state.website.pages.findIndex(
    pages => pages.id === page_id
  );
  state.website.pages.splice(pageIdx, 1);
}

/**
 * Fetch a website page from api.
 */
async function fetchPage(project_id, page_id) {
  const response = await Api().get(`projects/${project_id}/websitepages/${page_id}`);
  return { ...response.data.data, project_id };
}

async function loadPage(project_id, page_id) {
  state.page = null;
  state.page = await fetchPage(project_id, page_id);
}

async function reloadPage(project_id, page_id) {
  const data = await fetchPage(project_id, page_id);
  state.page = data;
  state.website.pages = state.website.pages.map(page => (page.id === page_id ? data : page));
}

/**
 * Create a website page.
 */
async function createPage(project_id, website_id, data) {
  const response = await Api().post(`projects/${project_id}/websitepages`, {
    website: website_id,
    ...data,
  });
  return { ...response.data.data, project_id };
}

/**
 * Update a website page.
 */
async function updatePage(project_id, page_id, data) {
  const response = await Api().patch(`projects/${project_id}/websitepages/${page_id}`, data);
  return { ...response.data.data, project_id };
}

/**
 * Delete a website page.
 */
async function deletePage(project_id, page_id) {
  await Api().delete(`projects/${project_id}/websitepages/${page_id}`);
}

/**
 * Fetch available website components of project from api.
 */
async function fetchComponentTypes(project_id) {
  const response = await Api().get(`projects/${project_id}/websitecomponents/available`);
  return response.data.data.components;
}

/**
 * Fetch a website component from api.
 */
async function fetchComponent(project_id, component_id) {
  const response = await Api().get(`projects/${project_id}/websitecomponents/${component_id}`);
  return { ...response.data.data, project_id };
}

/**
 * Create a website component.
 */
async function createComponent(project_id, data) {
  const response = await Api().post(`projects/${project_id}/websitecomponents`, data);
  return { ...response.data.data, project_id };
}

/**
 * Update a website component.
 */
async function updateComponent(project_id, component_id, data) {
  const response = await Api().patch(`projects/${project_id}/websitecomponents/${component_id}`, data);
  return { ...response.data.data, project_id };
}

/**
 * Delete a website component.
 */
async function deleteComponent(project_id, component_id) {
  await Api().delete(`projects/${project_id}/websitecomponents/${component_id}`);
}

/**
 * Sort website pages.
 */
async function updatePageSorting(project_id, website_id, ids) {
  const response = await Api().post(`projects/${project_id}/websites/${website_id}/sortpages`, {
    list: ids,
  });

  return response.data.data;
}

/**
 * Fetch project domains from api.
 */
async function fetchDomains(project_id, website_id) {
  const response = await Api().get(`projects/${project_id}/websitedomains`, {
    params: {
      website_id: website_id,
    },
  });
  return response.data.data.map(domains => {
    return { ...domains, project_id };
  });
}

/**
 * Fetch project domains from api.
 */
async function fetchDomain(project_id, domain_id) {
  const response = await Api().get(`projects/${project_id}/websitedomains/${domain_id}`);
  return { ...response.data.data, project_id };
}

/**
 * Create a website domain.
 */
async function createDomain(project_id, website_id, data) {
  const response = await Api().post(`projects/${project_id}/websitedomains`, {
    ...data,
    website_id,
  });
  return { ...response.data.data, project_id };
}

/**
 * Update a website domain.
 */
async function updateDomain(project_id, domain_id, data) {
  const response = await Api().patch(`projects/${project_id}/websitedomains/${domain_id}`, data);
  return { ...response.data.data, project_id };
}

/**
 * Delete a website domain.
 */
async function deleteDomain(project_id, domain_id) {
  await Api().delete(`projects/${project_id}/websitedomains/${domain_id}`);
}

/**
 * Sort website page components.
 */
async function updateComponentSorting(project_id, page_id, slot, ids) {
  const response = await Api().post(`projects/${project_id}/websitepages/${page_id}/sortcomponents`, {
    slot: slot,
    list: ids,
  });

  return response.data.data;
}

export default function useWebsite() {
  return {
    ...toRefs(state),

    fetchThemes,

    insertWebsite,
    loadWebsites,
    loadWebsite,
    loadPage,
    reloadPage,
    reloadWebsite,
    unloadPage,
    unloadWebsite,

    // Website CRUD.
    fetchWebsite,
    fetchWebsites,
    createWebsite,
    updateWebsite,
    deleteWebsite,

    // Page CRUD.
    fetchPage,
    createPage,
    updatePage,
    deletePage,

    // Component CRUD.
    fetchComponent,
    fetchComponentTypes,
    createComponent,
    updateComponent,
    deleteComponent,
    loadComponentTypes,

    // Domain CRUD
    fetchDomains,
    fetchDomain,
    createDomain,
    updateDomain,
    deleteDomain,

    updatePageSorting,
    updateComponentSorting,
  };
}
