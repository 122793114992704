export default [
  {
    path: "/",
    name: "home",
    redirect: "/login",
    meta: {
      title: "Home"
    }
  },
  {
    path: "/verify-user",
    name: "verify-user",
    component: () =>
      import(
        /* webpackChunkName: "VerifyUser" */ "@/modules/auth/views/templates/VerifyUser"
      )
  },
  {
    path: "/onboarding",
    name: "onboarding",
    meta: {
      title: "Onboarding"
    },
    component: () =>
      import(
        /* webpackChunkName: "Onboarding" */ "@/modules/core/views/templates/Onboarding"
      )
  },
  {
    path: "/projects/create",
    name: "project-create",
    meta: {
      title: "Project aanmaken"
    },
    component: () =>
      import(
        /* webpackChunkName: "ProjectCreate" */ "@/modules/core/views/templates/ProjectCreate"
      )
  },

  {
    path: "/projects/create-demo",
    name: "project-create-demo",
    meta: {
      title: "Demo aanmaken"
    },
    component: () =>
      import(
        /* webpackChunkName: "ProjectCreate" */ "@/modules/core/views/templates/ProjectCreateDemo"
      )
  },

  {
    path: "dashboard",
    name: "dashboard",
    projectScope: true,
    meta: {
      title: "Dashboard"
    },
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "@/modules/core/views/templates/Dashboard"
      )
  },
  {
    path: "connect",
    name: "connect",
    meta: {
      title: "Connect"
    },
    projectScope: true,
    component: () =>
      import(
        /* webpackChunkName: "Connect" */ "@/modules/core/views/templates/Connect"
      )
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: "Oeps! 404"
    },
    component: () =>
      import(
        /* webpackChunkName: "PageNotFound" */ "@/modules/core/views/templates/PageNotFound"
      )
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      title: "Privacy"
    },
    component: () =>
      import(
        /* webpackChunkName: "Privacy" */ "@/modules/core/views/templates/Privacy"
      )
  },
  {
    path: "/support",
    name: "support",
    meta: {
      title: "Support"
    },
    component: () =>
      import(
        /* webpackChunkName: "Support" */ "@/modules/core/views/templates/Support"
      )
  },
  {
    path: "request-module",
    name: "request-module",
    projectScope: true,
    component: () =>
      import(
        /* webpackChunkName: "RequestModule" */ "@/modules/core/views/templates/RequestModule"
      )
  },
  {
    path: "profile",
    name: "profile",
    meta: {
      title: "Profiel"
    },
    projectScope: true,
    component: () =>
      import(
        /* webpackChunkName: "Profile" */ "@/modules/core/views/templates/Profile"
      )
  },
  {
    path: "/designsystem",
    name: "designsystem",
    meta: {
      title: "Designsystem"
    },
    component: () =>
      import(
        /* webpackChunkName: "DesignSystem" */ "@/modules/core/views/templates/DesignSystem"
      )
  }
];
