import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isPlainObject from 'lodash/isPlainObject';

function applyAnyCondition(data, statement) {
  if (!isArray(statement.value)) {
    throw new Error('"any" conditions value must be of type array.');
  }

  const value = get(data, statement.field);

  return statement.value.includes(value);
}

function applyConditions(data, fieldConditions) {

  let conditions = fieldConditions;
  if (isString(fieldConditions)) {
    conditions = [{ field: fieldConditions }];
  } else if (isPlainObject(fieldConditions)) {
    conditions = [fieldConditions];
  }

  if (!isArray(conditions)) {
    throw new Error('Conditions must be of type array.');
  }

  return conditions.every(statement => {
    if (statement.field === undefined) {
      throw new Error('field is required for comparison.');
    }

    switch (statement.comparison || '=') {
      case '=':
        if (statement.value === undefined) {
          return get(data, statement.field);
        } else {
          return get(data, statement.field) === statement.value;
        }
      case '!=':
        return get(data, statement.field) !== statement.value;
      case '>':
        return get(data, statement.field) > statement.value;
      case '<':
        return get(data, statement.field) < statement.value;
      case '>=':
        return get(data, statement.field) >= statement.value;
      case '<=':
        return get(data, statement.field) <= statement.value;
      case 'any':
        return applyAnyCondition(data, statement);
      case '!any':
        return !applyAnyCondition(data, statement);
    }

    throw new Error(`Unknown comparison "${statement.comparison}".`);
  });
}

function fieldConditionsFor(name, data, field) {
  if (field[name]) {
    return applyConditions(data, field[name]);
  }
  return true;
}

export default function useFieldConditions() {
  return {
    applyConditions,
    fieldConditionsFor,
  };
}
