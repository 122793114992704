<template>
  <div v-if="isOpen" @click.stop>
    <slot
      :closeFlyout="closeFlyout"
      :isOpen="isOpen"
      :openFlyout="openFlyout"
      :toggleFlyout="toggleFlyout"
    />
  </div>
</template>

<script>
/**
 * example:
 *
 * <button @click.stop="$refs.flyout.toggleFlyout">Toggle</button>
 * <Flyout ref="flyout" v-slot:default="{ closeFlyout }">
 *    flyout <div @click="closeFlyout">close</div>
 * </Flyout>
 */

import { onUnmounted } from '@vue/composition-api';
import useFlyout from '@/compositions/useFlyout';

export default {
  name: 'Flyout',

  setup() {
    const {
      closeFlyout,
      isOpen,
      openFlyout,
      toggleFlyout,
      unmountFlyout,
    } = useFlyout();

    onUnmounted(() => {
      unmountFlyout();
    });

    return {
      isOpen,
      openFlyout,
      closeFlyout,
      toggleFlyout,
    };
  },
};
</script>
