export default [
  {
    projectScope: true,
    name: 'form',
    path: 'form',
    redirect: to => ({
      name: 'form-index',
      meta: {
        title: 'Formulieren',
      },
      params: {
        project_id: to.params.project_id,
        type: 'forms',
      },
    }),
  },
  {
    projectScope: true,
    name: 'form-index',
    path: 'form/:type',
    meta: {
      title: 'Formulier',
    },
    component: () =>
      import(
        /* webpackChunkName: "Index" */ '@/modules/form/views/templates/Index'
      ),

    children: [
      {
        projectScope: true,
        name: 'form-create',
        path: 'create',
        meta: {
          title: 'Formulier aanmaken',
        },
        component: () =>
          import(
            /* webpackChunkName: "Create" */ '@/modules/form/views/templates/Create'
          ),
        sidebar: true,
      },
      {
        projectScope: true,
        name: 'form-view',
        path: ':entry',
        meta: {
          title: 'Formulier bekijken',
        },
        component: () =>
          import(
            /* webpackChunkName: "View" */ '@/modules/form/views/templates/View'
          ),
        sidebar: true,
      },
      {
        projectScope: true,
        name: 'form-edit',
        path: ':entry/edit',
        meta: {
          title: 'Formulier bewerken',
        },
        component: () =>
          import(
            /* webpackChunkName: "Edit" */ '@/modules/form/views/templates/Edit'
          ),
        sidebar: true,
      },
    ],
  },
];
