<template>
  <header class="main-menu bg-prasset-green-700 flex flex-row" @mouseleave="closeMenu()">
    <button
      type="button"
      class="rounded-full bg-white w-6 h-6 flex items-center justify-center shadow absolute right-0 -mr-3 mt-20 focus:outline-none z-1"
      @click="toggleMenu"
    >
      <i
        class="leading-none transform"
        :class="{
          'ri-pushpin-fill rotate-0 text-sm': menuOpen && openOnHover,
          'ri-arrow-drop-right-line rotate-0 text-xl': !menuOpen && !openOnHover,
          'ri-arrow-drop-right-line rotate-180 text-xl': menuOpen && !openOnHover,
        }"
      />
    </button>

    <div class="w-16 h-full pt-6 relative main-menu__menu flex flex-col justify-between" :class="{ 'opened': menuOpen }">
      <router-link class="mx-5 flex-shrink flex flex-row mb-12 items-center" :to="{ name: 'dashboard' }" title="Dashboard">
        <div class="flex flex-row justify-center mr-4">
          <svg width="20" height="32" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none">
            <g fill="#FFF" fill-rule="evenodd">
              <path
              d="M4.049 31.773a.74.74 0 01-.734-.635L.008 8.15a.74.74 0 01.396-.765L14.723.08a.74.74 0 011.053.471l4.107 15.6a.74.74 0 01-1.012.868l-14.95-6.493a.74.74 0 01.59-1.359l13.55 5.885-3.484-13.235L1.549 8.464l2.807 19.508 4.51-12.607a.741.741 0 011.396.5L4.746 31.281a.741.741 0 01-.697.49"
              fill="#e2e8f0"
              />
            </g>
          </svg>
        </div>
        <transition name="menuIn">
          <p class="text-white font-medium text-base truncate" v-if="menuOpen">{{ project.name }}</p>
        </transition>
      </router-link>

      <div class="ml-5 overflow-auto mb-6 flex-1 relative">
        <nav class="w-full flex flex-col justify-between whitespace-no-wrap">
          <MenuItem
            icon="ri-community-line"
            :item="{ name: $tuf('pim'), route: 'pim' }"
            :subItems="[
              { name: $tuf('assets'), route: 'assets' },
              { name: $tuf('assetgroups'), route: 'assetgroups' },
            ]"
          />

          <MenuItem
            icon="ri-parent-line"
            :item="{ name: $tuf('crm'), route: 'crm' }"
            :subItems="[
              { name: $tuf('people'), route: 'people' }
            ]"
          />

          <!--
          <MenuItem
            icon="ri-file-list-line"
            :item="{ name: $tuf('forms'), route: 'form' }"
            :subItems="[
              { name: $tuf('forms'), route: 'forms' },
              { name: $tuf('submissions'), route: 'formsubmissions' },
              { name: $tuf('notifications'), route: 'formnotifications' }
            ]"
          />

          <MenuItem
            icon="ri-mac-line"
            :item="{ name: $tuf('websites'), route: 'website' }"
          />

          <MenuItem
            icon="ri-map-2-line"
            :item="{ name: $tuf('views'), route: 'map' }"
            :subItems="[
              { name: $tuf('views'), route: 'maps', absolute: true },
              { name: $tuf('collections'), route: 'mapsets', absolute: true },
            ]"
          />
          -->

          <MenuItem
            icon="ri-file-list-line"
            :item="{ name: $tuf('presentation'), route: 'website' }"
            route="website"
            :active="presentationsActive"
            :subItems="[
              { name: $tuf('websites'), route: 'website', absolute: true },
              { name: $tuf('offer'), route: 'offer', absolute: true },
              { name: $tuf('interactive_maps'), route: 'mapsets', absolute: true },
              { name: $tuf('forms'), route: 'form', absolute: true },
            ]"
          />
        </nav>
      </div>

      <div class="flex pb-4" :class="{
        'flex-col items-center justify-end': !menuOpen,
        'flex-row-reverse items-center justify-end': menuOpen
      }">
        <router-link :to="{ name: 'settings' }" @click="projectsOpen = !projectsOpen" class="mx-4 focus:outline-none" :class="{ 'mt-4': menuOpen }" :title="$tuf('settings')">
          <i class="ri-settings-4-line text-prasset-gray-100 text-2xl"></i>
        </router-link>
        <button type="button" @click="projectsOpen = !projectsOpen" class="mx-4 focus:outline-none" :class="{ 'mt-8': !menuOpen, 'mt-4': menuOpen }" :title="$tuf('projects')">
          <ProjectMenu class="cursor-pointer" />
        </button>
        <MeMenu class="mx-4" :class="{ 'mt-8': !menuOpen, 'mt-4': menuOpen }" :title="$tuf('account')" />
      </div>
    </div>

    <!-- Menu hover activators -->
    <div class="h-1 absolute inset-x-0 top-0 mt-32" @mouseover="openMenu"></div>
    <div class="h-1 absolute inset-x-0 bottom-0 mb-48" @mouseover="openMenu"></div>
    <div class="w-3 absolute inset-y-0 right-0 mt-32 mb-48" @mouseover="openMenu"></div>

    <transition name="projectsIn">
      <ProjectsMenu />
    </transition>
  </header>
</template>

<script>
import { computed, watch, ref } from '@vue/composition-api';
import { menuOpen, projectsOpen, manuallyControlled } from '@/compositions/useNavigation';
import { project } from '@/compositions/useProjects';
import MeMenu from '@/modules/core/views/components/MeMenu';
import MenuItem from '@/components/MenuItem';
import ProjectMenu from '@/components/ProjectMenu';
import ProjectsMenu from '@/modules/core/views/components/ProjectsMenu';

export default {
  components: {
    MenuItem,
    MeMenu,
    ProjectsMenu,
    ProjectMenu,
  },

  setup(props, { root }) {
    const openOnHover = ref(false);

    const current_route = computed(() => {
      return root.$route.name;
    });

    let hoverTimeout = null;

    function toggleMenu() {
      manuallyControlled.value = true;
      if (openOnHover.value === true) {
        openOnHover.value = false;
        menuOpen.value = true;
      } else {
        menuOpen.value = !menuOpen.value;
      }
    }

    function openMenu() {
      if (menuOpen.value === true) return;
      clearTimeout(hoverTimeout);
      hoverTimeout = setTimeout(() => {
        openOnHover.value = true;
        menuOpen.value = true;
      }, 250);
    }

    function closeMenu() {
      clearTimeout(hoverTimeout);
      if (openOnHover.value === true) {
        menuOpen.value = false;
        openOnHover.value = false;
      }
    }

    const presentationsActive = computed(() => {
      return root.$route.name.indexOf('website') === 0 ||
             root.$route.name.indexOf('form') === 0 ||
             root.$route.name.indexOf('map') === 0 ||
             root.$route.name.indexOf('offer') === 0;
    });

    watch(current_route, () => {
      if (root.$route.name === 'dashboard' && manuallyControlled.value === false) {
        menuOpen.value = true;
      }
    });

    return {
      openMenu,
      closeMenu,
      project,
      projectsOpen,
      presentationsActive,
      menuOpen,
      manuallyControlled,
      toggleMenu,
      openOnHover,
    };
  },
};
</script>

<style lang="scss" scoped>
.menuIn-enter-active, .menuIn-leave-active {
  transition: opacity 150ms ease-in;
}

.menuIn-enter,
.menuIn-leave-to{
  opacity: 0;
}
</style>
