export default [
  {
    projectScope: true,
    name: 'pim',
    path: 'pim',
    redirect: to => ({
      name: 'pim-index',
      params: {
        project_id: to.params.project_id,
        type: 'assets',
      },
    }),
  },
  {
    projectScope: true,
    name: 'pim-index',
    meta: {
      title: 'Objecten',
    },
    path: 'pim/:type',
    component: () =>
      import(
        /* webpackChunkName: "Index" */ '@/modules/pim/views/templates/Index'
      ),
    children: [
      {
        projectScope: true,
        name: 'pim-create',
        meta: {
          title: 'Object aanmaken',
        },
        path: 'create',
        component: () =>
          import(
            /* webpackChunkName: "Create" */ '@/modules/pim/views/templates/Create'
          ),
        sidebar: true
      },
      {
        projectScope: true,
        name: 'pim-view',
        meta: {
          title: 'Object bekijken',
        },
        path: ':entry',
        component: () =>
          import(
            /* webpackChunkName: "View" */ '@/modules/pim/views/templates/View'
          ),
        sidebar: true
      },
      {
        projectScope: true,
        name: 'pim-edit',
        meta: {
          title: 'Object bewerken',
        },
        path: ':entry/edit',
        component: () =>
          import(
            /* webpackChunkName: "Edit" */ '@/modules/pim/views/templates/Edit'
          ),
        sidebar: true,
      },
      {
        projectScope: true,
        name: 'pim-contracts',
        meta: {
          title: 'Contract',
        },
        path: ':entry/contracts',
        component: () =>
          import(
            /* webpackChunkName: "ContractsPim" */ '@/modules/pim/views/templates/Contracts'
          ),
        sidebar: true,
      },
    ],
  },
];
