import { toRefs, reactive, ref } from '@vue/composition-api';
import { generateUUID } from '@/providers/helpers';

const instances = ref({});

function closeFlyouts() {
  Object.keys(instances.value).forEach(luuid => {
    instances.value[luuid].closeFlyout();
  });
}

document.addEventListener('click', closeFlyouts);

function Flyout() {
  const uuid = generateUUID();
  const self = this;

  this.state = reactive({
    isOpen: false,
  });

  this.closeOthers = function() {
    Object.keys(instances.value).forEach(luuid => {
      if (luuid !== uuid) {
        instances.value[luuid].closeFlyout();
      }
    });
  };

  this.openFlyout = function() {
    self.state.isOpen = true;
    self.closeOthers();
    instances.value[uuid] = this;
  };

  this.closeFlyout = function() {
    self.state.isOpen = false;
    delete instances.value[uuid];
  };

  this.toggleFlyout = function() {
    if (self.state.isOpen) {
      self.closeFlyout();
    } else {
      self.openFlyout();
    }
  };

  this.unmountFlyout = function() {
    delete instances.value[uuid];
  };
}

export default function useFlyout() {
  const {
    state,
    openFlyout,
    closeFlyout,
    toggleFlyout,
    unmountFlyout,
  } = new Flyout();

  return {
    ...toRefs(state),
    openFlyout,
    closeFlyout,
    toggleFlyout,
    unmountFlyout,
    closeFlyouts,
  };
}
